import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { verify_icon, noImageFound, ens_white } from '../../assets/Index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { rank_icon } from '../../assets/Index';

export default function TokenCard(props) {
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  const isEnsCard =
    props.contractAddress === '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85';
  return (
    <div className="tokencard-container">
      <Link
        to={props.redirectLink ? props.redirectLink : '/#'}
        aria-label={props?.tokenTitle}
      >
        {isEnsCard ? (
          <div
            className={classNames(
              'ensimage-container',
              isEnsCard ? 'custom-img' : ''
            )}
            style={
              isEnsCard
                ? {
                    background:
                      'linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%)',
                  }
                : { backgroundImage: '' }
            }
          >
            <img src={ens_white} width="50" />
            <h5
              className="text-nowrap-full"
              title={props?.tokenTitle}
              style={{ maxWidth: '120px' }}
            >
              {props?.tokenTitle}
            </h5>
          </div>
        ) : (
          <LazyLoadImage
            alt={props?.tokenTitle}
            src={props?.tokenImage || noImageFound}
            wrapperClassName="img-fluid token-img"
            //  placeholderSrc={noImageFound}
            effect="blur"
          />
        )}
      </Link>
      <div className="token-title-container">
        <h2 className="title" title={props?.tokenTitle}>
          {props?.tokenTitle}
        </h2>
        {!props.hideVerified && (
          <img src={verify_icon} className="verify-icon" alt="verify-icon" />
        )}
      </div>
      {/* <div className="token-creator-container">
                <img className="img-fluid creator-img"
                    src={by_token}
                />
                <span className="by-text">BY</span>
                <span>NULLAddress</span>
            </div> */}
      {props.showPriceInfo && (
        <div className="token-price-container">
          <div className="row">
            <div className="col-6 pr-2">
              <div className="price-container">
                <>
                  {props?.showRarity ? (
                    <>
                      <span className="price-label">Rarity</span>
                      <span className="price-value d-flex align-items-center">
                        {props?.rank ? (
                          <>
                            <img src={rank_icon} className="rank-icon" />
                            <span className="text-white">{props?.rank}</span>
                          </>
                        ) : (
                          '-'
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="price-label">Floor Price</span>
                      <span className="price-value">
                        {props?.floorPrice ? (
                          <>
                            {props?.floorPrice}{' '}
                            <span className="text-dark-light">
                            {chains[props?.chain] || 'ETH'}
                            </span>
                          </>
                        ) : (
                          '-'
                        )}
                      </span>
                    </>
                  )}
                </>
              </div>
            </div>
            <div className="col-6 pl-2">
              <div className="traded-container">
                <span className="d-none d-md-block price-label">
                  {props.isTotalSupply == true
                    ? 'Total Supply'
                    : props.isTwentyFourHourVolume
                    ? 'Token Owned'
                    : 'Volume Traded'}
                </span>
                <span className="d-block d-md-none price-label">
                  {props.isTotalSupply == true
                    ? 'Total Supply'
                    : props.isTwentyFourHourVolume
                    ? 'Token Owned'
                    : 'Volume'}
                </span>
                <span className="price-value">
                  {props.isTotalSupply == true ? (
                    props.totalSupply
                  ) : props?.volumeTraded ? (
                    <>
                      {props?.volumeTraded}{' '}
                      {props.isTwentyFourHourVolume ? (
                        ''
                      ) : (
                        <span className="text-dark-light">
                          {' '}
                          {chains[props?.chain] || 'ETH'}
                        </span>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <Link
        to={props.redirectLink ? props.redirectLink : '/#'}
        state={{ obj: props.itemDetails }}
        className="view-btn"
        aria-label={props?.tokenTitle}
      >
        View Now
      </Link>
    </div>
  );
}
