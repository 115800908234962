import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import PageTitle from '../../components/pagetitle/PageTitle';
import Table from '../../components/table/Table';
import { useNavigate } from 'react-router-dom';
import { apicallback, copyClipboard } from '../../callbacks/index';
import { openToasterAlert } from '../../components/alert/Index';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DeleteModel from '../../components/models/DeleteModel';
import { Bars } from 'react-loader-spinner';
import usePagination from '../../hooks/usePagination';
import WAValidator from 'multicoin-address-validator';

import {
  clipboard_img,
  frame_trash_icon,
  frame_view_icon,
} from '../../assets/Index';
import LoginSignUp from '../../components/models/LoginSignUp';
//Helper
import { generateColumns, generateSuggestedColumns } from './helper';
//Assets
import { FiSearch, FaTrash } from '../../components/icons/Index';

const Page_Limit = 15;
export default function WalletTracker() {
  const setWalletSummaryActiveTabId = useStoreActions(
    (actions) => actions.setWalletSummaryActiveTabId
  );
  const authorization = useStoreState((state) => state.authorization);
  const [wallets, setWallets] = useState([]);
  const [chainId, setChainId] = useState('1');
  const [walletAddress, setWalletAddress] = useState([]);
  const [suggestWallet, setSuggestWallet] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showLoginModalState, setShowLoginModalState] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState({
    id: '',
    name: '',
  });
  const { paginationRef, updatePagination, nextPageHandler } =
    usePagination(Page_Limit);
  const [isLoadingWalletsState, setIsLoadingWalletState] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [openWallets, setOpenWallets] = useState(
    localStorage.getItem('open_wallets')
      ? JSON.parse(localStorage.getItem('open_wallets'))
      : null
  );
  const history = useNavigate();

  useEffect(() => {
    if (authorization) {
      getWallets();
    }
    getSuggestWallet();
  }, [authorization]);

  useEffect(() => {
    if (!authorization) {
      if (openWallets) {
        const openWalletsList = Object.values(openWallets).map((item) => item);
        setWallets(openWalletsList);
      } else {
        setWallets([]);
      }
    }
  }, [openWallets, authorization]);

  async function getSuggestWallet() {
    const response = await apicallback(
      null,
      '/getSuggestedWallets',
      {},
      'private',
      null,
      'get'
    );
    if (response && response.status == 'success') {
      setSuggestWallet(response.data);
    }
  }

  const getWallets = async (
    otherProps = null,
    clearPrevCollections = false
  ) => {
    const { offset, limit } = paginationRef.current;
   
    const payload = {
      user_id: authorization.data.id.toString(),
      offset,
      limit,
      ...otherProps,
    };
    setIsLoadingWalletState(true);
    const response = await apicallback(
      null,
      `/getWalletsV2?offset=${offset}`,
      {},
      'private',
      authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      let hasOffset = false;
      if (response.offset) {
        hasOffset = true;
      }
      updatePagination({ canLoadMore: hasOffset });
      if (clearPrevCollections) {
        setWallets(response.data);
      } else {
        const updateWallet = [].concat(wallets, ...response.data);
        setWallets(updateWallet);
      }
    }
    setIsLoadingWalletState(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid;
    let is_ens = false;
    if (walletAddress.endsWith('.eth')) {
      valid = is_ens = true;
    } else {
      valid = WAValidator.validate(
        walletAddress,
        chains[chainId].toLowerCase()
      );
    }

    if (valid) {
    } else {
      openToasterAlert(
        'error',
        'Wallet address is not valid for selected chain. Please check the address and chain selected and try again.'
      );
      return;
    }

    setSearchLoader(true);
    if (authorization) {
      try {
        const response = await apicallback(
          null,
          '/createWalletsV2',
          {
            name: `Wallet ${wallets.length + 1}`,
            user_id: authorization.data.id.toString(),
            address: walletAddress,
            chain_id: is_ens ? 1 : chainId,
            hexCode: '#fec60d',
          },
          'private',
          authorization.token
        );
        if (response && response.status == 'success') {
          localStorage.setItem('is_recent_search', 1);
          setWalletSummaryActiveTabId();
          history(
            `/wallet-info/${response.data.wallet_id}/${response.data.address}/${chainId}`
          );
        } else {
          openToasterAlert(
            'error',
            response.message ? response.message : 'Wallet address is not valid.'
          );
        }
        setSearchLoader(false);
      } catch (error) {
        setSearchLoader(false);
        openToasterAlert('error', 'Wallet address is not valid.');
      }
    } else {
      handelPublicWalletSubmit();
    }
  };

  const handelPublicWalletSubmit = async () => {
    if (walletAddress != '') {
      const response = await apicallback(
        null,
        `/convertAddress?address=${walletAddress}`,
        {},
        null,
        null,
        'get'
      );

      if (response && response.status == 'success') {
        localStorage.setItem('is_recent_search', 1);
        let c = openWallets ? openWallets : {};
        const address = response?.data?.address;
        c[address] = {
          wallet_id: Object.keys(c).length + 1,
          name: `Wallet ${Object.keys(c).length + 1}`,
          address: address,
          collection_count: 0,
          nft_count: 0,
          ens_count: 0,
          chain_id: chainId,
        };
        localStorage.setItem('open_wallets', JSON.stringify(c));
        setOpenWallets({ ...c });
        setWalletSummaryActiveTabId();
        history(`/wallet-info/${c[address].wallet_id}/${address}/${chainId}`);
      } else {
        openToasterAlert('error', 'Wallet address is not valid.');
      }
      setSearchLoader(false);
    } else {
      setSearchLoader(false);
    }
  };

  const handelDelete = async () => {
    const response = await apicallback(
      null,
      '/deleteWalletV2',
      {
        id: selectedWallet.id,
        user_id: authorization.data.id.toString(),
      },
      'private',
      authorization.token
    );
    if (response && response.status == 'success') {
      const { currentPageNo } = paginationRef.current;
      let updatedLimit = (Number(currentPageNo) + 1) * Page_Limit;
      if (updatedLimit - (wallets.length - 1) >= Page_Limit) {
        updatePagination({ currentPageNo: currentPageNo - 1 });
        updatedLimit = updatedLimit - Page_Limit;
      }
      getWallets({ offset: 0, limit: updatedLimit }, true);
    }
  };

  const copyFromClipboardNew = (id) => {
    navigator.clipboard.readText().then((copiedText) => {
      setWalletAddress(copiedText);
      document.getElementById(`${id}`).value = copiedText;
    });
  };

  function onChangeHndlr(event) {
    setWalletAddress(event.target.value.replace(' ', ''));
  }

  function viewWalletDetailsHandler(event) {
    const walletId = event.currentTarget.getAttribute('data-id');
    if (Array.isArray(wallets) && walletId) {
      const walletInfo = wallets.find(
        (item) => item.wallet_id.toString() === walletId
      );
      if (walletInfo) {
        const { address } = walletInfo;
        setWalletSummaryActiveTabId();
        history(`/wallet-info/${walletId}/${address}/${chainId}`);
      }
    }
  }

  function deleteWalletHandler(event) {
    const walletId = event.currentTarget.getAttribute('data-id');
    if (Array.isArray(wallets) && walletId) {
      const walletInfo = wallets.find(
        (item) => (item?.wallet_id).toString() === walletId
      );
      if (walletInfo && authorization) {
        setModalShow(true);
        setSelectedWallet({
          id: walletInfo.wallet_id,
          name: walletInfo.name,
        });
      } else if (walletInfo) {
        const { address } = walletInfo;
        let c = openWallets;
        delete c[address];
        setOpenWallets({ ...c });
        localStorage.setItem(
          'open_wallets',
          Object.keys(c).length ? JSON.stringify(c) : null
        );
      }
    }
  }

  const menuOptions = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: viewWalletDetailsHandler,
      },
      {
        id: 'delete',
        actionIcon: (
          <img
            className="ml-2"
            src={frame_trash_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'Delete',
        onClickHndlr: deleteWalletHandler,
      },
    ];
  }, [wallets, openWallets, authorization]);
  const menuOptions2 = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: handleSubmitForSuggested,
      },
    ];
  }, [wallets, openWallets, authorization]);

  async function handleSubmitForSuggested(value) {
    setWalletAddress(value);
    setChainId(1);
    setTimeout(async () => {
      document.getElementById('searching-btn-container').click();
    }, 500);
  }

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  function redirectHandlr(event) {
    event.preventDefault();
    const walletId = event.currentTarget.getAttribute('data-id');
    if (Array.isArray(wallets)) {
      const walletInfo = wallets.find(
        (item) => String(item.wallet_id) === walletId
      );
      if (walletInfo) {
        setWalletSummaryActiveTabId();
        history(
          `/wallet-info/${walletInfo.wallet_id}/${walletInfo.address}/${walletInfo.chain_id}`
        );
      }
    }
  }

  async function subscribeHandler(event) {
    const walletId = event.currentTarget.getAttribute('data-id');
    if (!authorization) {
      setShowLoginModalState(true);
      return;
    }
    if (walletId) {
      try {
        const response = await apicallback(
          null,
          '/updateWalletDetailsv2',
          {
            id: walletId,
            email_alerts: event.target.checked,
          },
          'private',
          authorization.token
        );
        if (response.status === 'success') {
          const { currentPageNo } = paginationRef.current;
          let updatedLimit = (Number(currentPageNo) + 1) * Page_Limit;
          getWallets({ offset: 0, limit: updatedLimit }, true);
        }
      } catch (e) {
        toast.error('Something went wrong.');
      }
    }
  }

  function hideAuthorizeModal() {
    setShowLoginModalState(false);
  }

  const tableRowData = useMemo(() => {
    return { isAuthorize: !!authorization };
  }, [authorization]);

  function loadMoreHandler() {
    setIsLoadingWalletState(true);
    nextPageHandler();
    getWallets();
  }

  const suggestedColumns = useMemo(
    () =>
      generateSuggestedColumns(
        menuOptions2,
        copyAddressHandler,
        handleSubmitForSuggested
      ),
    []
  );

  const trackerColumns = useMemo(
      () =>
        generateColumns(
          menuOptions,
          copyAddressHandler,
          redirectHandlr,
          subscribeHandler
        ),
      [wallets]
    ),
    showTableLoading = (wallets.length === 0 && isLoadingWalletsState) || false,
    loadMoreLoading = (wallets.length > 0 && isLoadingWalletsState) || false;

  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  return (
    <React.Fragment>
      <DeleteModel
        title={`Remove ${selectedWallet.name}`}
        text="Are you sure you want to remove this wallet?"
        delete={handelDelete}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <PageTitle
        meta={{
          title: 'The Most Advanced Web3 and NFT Wallet Tracker | ANZALI',
          description:
            'NFT wallet tracker with instant analytics about NFT collections, floor prices, rarity scores and transactions. Use GPT for analyzing blockchain wallets.',
        }}
      />
      <div className="page-container">
        <section className="tf-section tf-rank">
          <div className="wallettracker-container">
            <div className="row justify-content-center">
              <div className="col-11 col-md-7 col-lg-5 col-xl-4 mb-7">
                <div className="title-container">
                  <h2 className="page-title">Wallet Tracker</h2>
                  <h5 className="page-subtitle">
                  Select Your Chain and Enter Any Wallet Address
                  </h5>
                </div>
                <form noValidate="novalidate" onSubmit={handleSubmit}>
                <div className="mb-4 d-flex justify-content-around w-100 chain-list">
                  {Object.entries(chains).map(([k, v]) => (
                    <span
                      key={k}
                      className={`${chainId == k ? 'active' : ''}`}
                      onClick={() => setChainId(k)}
                    >
                      {v}
                    </span>
                  ))}
                </div>
                  <div className="searchwallet-container">
                    <input
                      type="text"
                      id="walletAddress"
                      name="walletAddress"
                      className="search-input"
                      value={walletAddress}
                      onChange={onChangeHndlr}
                      placeholder="Enter any wallet address…"
                    />

                    <button
                      className="searching-btn-container"
                      id="searching-btn-container"
                    >
                      {searchLoader ? (
                        <Bars color="#ffffff" height={20} width={20} />
                      ) : (
                        <FiSearch />
                      )}
                    </button>
                  </div>
                </form>
                <span
                  className="d-flex align-items-center justify-content-center mt-2 paste-container"
                  onClick={() => copyFromClipboardNew('walletAddress')}
                >
                  <img
                    src={clipboard_img}
                    className="copy-icon"
                    alt="Anzali Search"
                  />{' '}
                  <span>Paste from clipboard</span>
                </span>
               
              </div>
              {suggestWallet.length ? (
                <div className="col-12 mb-4">
                  <div className="trackerdetail-container">
                    <div className="wallet-actions">
                      <h4>Suggested Wallets</h4>
                    </div>
                    <div className="mb-4">
                      <Table
                        columns={suggestedColumns}
                        data={Array.isArray(suggestWallet) ? suggestWallet : []}
                        rowDataInfo={tableRowData}
                        tableHeadClass="text-capitalize"
                        // isDataLoading={isLoadingWalletsState}
                        isDataLoading={false}
                        onClickHandler={null}
                        loadingMessage="Loading Wallets..."
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {wallets.length > 0 ? (
                <div className="col-12 mb-2">
                  <div className="trackerdetail-container">
                    <div className="wallet-actions">
                      <h4>Recently Searched</h4>
                      {!authorization && wallets.length > 0 && (
                        <button
                          className="btn-outline-danger"
                          type="button"
                          onClick={() => {
                            setOpenWallets(null);
                            localStorage.removeItem('open_wallets');
                          }}
                        >
                          <FaTrash className="mr-2" /> Clear All
                        </button>
                      )}
                    </div>
                    <div className="mb-4">
                      <Table
                        columns={trackerColumns}
                        data={Array.isArray(wallets) ? wallets : []}
                        rowDataInfo={tableRowData}
                        tableHeadClass="text-capitalize"
                        // isDataLoading={isLoadingWalletsState}
                        isDataLoading={showTableLoading}
                        onClickHandler={subscribeHandler}
                        loadingMessage="Loading Wallets Collections..."
                      />
                    </div>
                    {/* <div className="d-none">
                                        <div className="wallets-container">
                                            {Array.isArray(wallets) && wallets.map(item => (
                                                <div className="wallet-card">
                                                    <div className="wallet-header">
                                                        <div className="d-flex">
                                                            <span className="wallet-icon mr-3" style={{ backgroundColor: item?.hexCode || '#FEC60D' }}>
                                                            </span>
                                                            <div className="d-flex flex-column">
                                                                <h4 className="font-18 mb-2">{item?.name}</h4>
                                                                <div className="d-flex">
                                                                    <div className="balance-info">
                                                                        <span className="text-muted mr-1">NFTs: </span>
                                                                        <span className="text-custom-primary">{getTwoDigitValue(item.nft_count)}</span>
                                                                    </div>
                                                                    <div className="balance-info">
                                                                        <span className="text-muted mr-1">Collections: </span>
                                                                        <span className="text-custom-primary">{getTwoDigitValue(item.collection_count)}</span>
                                                                    </div>
                                                                    <div className="balance-info">
                                                                        <span className="text-muted mr-1">ENS: </span>
                                                                        <span className="text-custom-primary">{getTwoDigitValue(item.ens_count)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-body">
                                                        <div className="d-flex flex-column">
                                                            <span className="text-muted mb-2">Portfolio Value</span>
                                                            <span ><span className="mr-1">
                                                                {item.portfolioValue}
                                                            </span><span className="text-muted">-</span></span>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <span className="text-muted mb-2">Realised Profit</span>
                                                            <span ><span className="text-success-dark mr-1">
                                                                {item.realizedProfit}
                                                            </span><span className="text-muted">-</span></span>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <span className="text-muted mb-2">Last Activity</span>
                                                            <span >-</span>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-footer">
                                                        <MenuOptions
                                                            actionOptions={menuOptions}
                                                            rowId={item?.wallet_id}
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div> */}

                    <div className="center">
                      {paginationRef.current.canLoadMore && (
                        <button
                          onClick={loadMoreHandler}
                          className="sc-button style-4"
                        >
                          {loadMoreLoading ? (
                            <Bars color="#ffffff" height={30} width={80} />
                          ) : (
                            'Load More'
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </section>
      </div>

      <LoginSignUp
        show={showLoginModalState}
        onHide={hideAuthorizeModal}
        text={
          'Sign up or sign in to subscribe and receive email alerts for wallet activity.'
        }
        url={false}
      />
    </React.Fragment>
  );
}
