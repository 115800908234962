import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import axios from 'axios';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';
import { FaUserEdit } from 'react-icons/fa';
import { BsFacebook } from 'react-icons/bs';
import { AiFillRedditCircle, AiOutlineLink } from 'react-icons/ai';
import LoginSignUp from '../../components/models/LoginSignUp';
import AnzaliLoader from '../../components/AnzaliLoader';
import Tabs from '../../components/Tabs';
import AddWalletModal from './AddWalletModal';
import PageTitle from '../../components/pagetitle/PageTitle';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { openToasterAlert } from '../../components/alert/Index';
//Store
import { Tooltip } from 'react-tooltip';

//components
import {
  openUrl,
  copyClipboard,
  apicallback,
  condenseAddress,
} from '../../callbacks/index';
//Helper
import { getTabOptions } from './helper';
//Images
import {
  bucket_icon,
  dummy_wallet_img,
  chat_gpt_icon,
  document_copy_img,
} from '../../assets/Index';
import { ReactComponent as TwitterXIcon } from '../../assets/images/icon/twitterx_icon.svg';
//Icons
//TabComponent
import Overview from './Overview';
import Collections from './Collections';
import Assets from './Assets';
import EnsAssets from './EnsAssets';
import Transactions from './Transactions';
import AllTransactions from './AllTransactions';
import Performance from './Performance';
import BlueChips from './BlueChips';
import ChatModal from './ChatModal';
import { useEffect } from 'react';
import {
  getDisplayValueWithDash,
  getValueWithDecimal,
} from '../../utility/commonUtitlity';
import { toast } from 'react-toastify';
import SupportWarningModal from './SupportWarningModal';

function getActiveMenuComponent(tabId) {
  switch (tabId) {
    case 'overview':
      return Overview;
    case 'collections':
      return Collections;
    case 'assets':
      return Assets;
    case 'ens-assets':
      return EnsAssets;
    case 'nft-transactions':
      return Transactions;
    case 'all-transactions':
      return AllTransactions;
    case 'performance':
      return Performance;
    case 'blue-chips-collections':
      return BlueChips;
    case '':

    default:
      return null;
  }
}

export default function WalletInfo() {
  const currentPageUrl = window.location.href;
  const {
    id: walletId,
    wallet_address: walletAddress,
    chain_id: chainIdFromUrl,
  } = useParams();
  const [walletListeningState, setWalletListeningState] = useState(false);
  const [walletImage, setWalletImage] = useState(null);
  const [showLoginModalState, setShowLoginModalState] = useState(false);
  const [loginModalTextState, setLoginModalTextState] = useState('');
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [walletInfoState, setWalletInfoState] = useState(null);
  const [isWalletSyncingState, setIsWalletSyncingState] = useState(false);
  const [showSupportModalState, setShowSupportModalState] = useState(false);
  const [addWalletModalState, setAddWalletModalState] = useState(false);
  const [colorPickerBtnState, setColorPickerBtnState] = useState({
    currentColor: '#F9D423',
    hasColorChanged: false,
  });
  /////V2 Data Start
  const [walletOverview, setWalletOverview] = useState(null);
  const [walletOverviewDataList, setWalletOverviewDataList] = useState(null);
  const [walletOverviewChart, setWalletOverviewChart] = useState(null);
  /////V2 Data End
  const [editWalletState, setEditWalletState] = useState({
    isEditWalletLoadingState: false,
    showModal: false,
    editName: '',
  });
  const authorization = useStoreState((state) => state.authorization);
  const walletSummaryActiveTabId = useStoreState(
    (state) => state.walletSummaryActiveTabId
  );
  const setWalletSummaryActiveTabId = useStoreActions(
    (actions) => actions.setWalletSummaryActiveTabId
  );
  //Chat ModalState
  const [showChatModalState, setShowChatModalState] = useState({
    showModal: false,
    chatWalletDetails: undefined,
  });
  const [chartValue, setChartValue] = useState('24h');

  const updateListeningWallet = async (type) => {
    await apicallback(
      null,
      '/updateWalletDetailsv2',
      {
        id: walletId,
        email_alerts: type,
      },
      'private',
      authorization.token
    );
  };

  useEffect(() => {
    if (walletId && walletAddress) {
      getAnalyticsApiCall();
      getSuggestWallet();
      getWalletPortfolioChart();
    }
  }, [walletId, walletAddress]);

  async function getSuggestWallet() {
    const response = await apicallback(
      null,
      '/getSuggestedWallets',
      {},
      'private',
      null,
      'get'
    );
    if (response && response.status == 'success') {
      const getImage = response.data.filter((element) => {
        return element.address.toLowerCase() == walletAddress.toLowerCase()
          ? element.image
          : null;
      });
      setWalletImage(getImage.length ? getImage[0].image : '');
    }
  }

  async function getAnalyticsApiCall() {
    try {
      const { data, token } = authorization || {},
        { id: userId } = data || {};
      const payload = {
        address: walletAddress,
        wallet_id: walletId,
        user_id: userId,
      };
      setIsLoadingState(true);
      setIsWalletSyncingState(true);
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_ENDPOINT_V2}/tracker?address=${payload.address}&id=${payload.wallet_id}&chain_id=${chainIdFromUrl}`,
        {
          headers: authorization
            ? {
                'content-type': 'application/json',
                Authorization: `Token ${token}`,
              }
            : null,
        }
      );

      if (response.data.status === 200) {
        const statics = response.data.data.counts;
        setWalletOverview({
          activity_num: statics.activity_num,
          age: statics.age,
          blue_chip_count: statics.blue_chip_count,
          collection_count: statics.collection_count,
          ens_count: statics.ens_count,
          ether_balance: statics.ether_balance,
          is_blue_chip_holder: statics.is_blue_chip_holder,
          latest_transaction: statics.latest_transaction,
          mint_count: statics.mint_count,
          mint_num: statics.mint_num,
          nft_count: statics.nft_count,
          nft_tx_count: statics.nft_tx_count,
          chain: statics.chain,
          pnl_value: statics.pnl_value.eth,
          realized_profit: statics.realized_profit.eth,
          portfolio_value: statics.portfolio_value.eth,
          unrealized_profit: statics.unrealized_profit.eth,
          portfolio_history_24h: statics.portfolio_history_24h.eth,
          total_revenue: statics.total_revenue.eth,
          total_spent: statics.total_spent.eth,
          buy_num: statics.buy_num,
          sell_num: statics.sell_num,
        });

        setWalletOverviewDataList({
          latest_transaction_list: response.data.data.latest_transaction,
          walletCollections: response.data.data.walletCollections,
          ens: response.data.data.ens,
          walletNFTs: response.data.data.walletNFTs,
        });
        ////V2 End
        const { wallet_info } = response.data.data || {};
        setWalletInfoState(wallet_info);
        setWalletListeningState(wallet_info ? wallet_info.email_alerts : false);
        setColorPickerBtnState((prevState) => ({
          ...prevState,
          currentColor: wallet_info?.hexCode,
        }));
      } else {
        toast.error('Something went wrong.');
      }
      setIsLoadingState(false);
    } catch (error) {
      toast.error('Something went wrong');
      setIsLoadingState(false);
    }
  }

  async function getWalletPortfolioChart(chartValueV2 = chartValue) {
    try {
      if (chainIdFromUrl == 1) {
        setChartValue(chartValueV2);
        const { data, token } = authorization || {},
          { id: userId } = data || {};
        const payload = {
          address: walletAddress,
          wallet_id: walletId,
          user_id: userId,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_NODE_ENDPOINT_V2}/getWalletPortfolioChart?address=${payload.address}&timerange=${chartValueV2}`,
          {
            headers: authorization
              ? {
                  'content-type': 'application/json',
                  Authorization: `Token ${token}`,
                }
              : null,
          }
        );
        if (response.data.status === 200) {
          const res = response.data.data;
          setWalletOverviewChart(res);
        }
      } else {
        return;
      }
    } catch (error) {}
  }
  const idsToHide = [
    'blue-chips-collections',
    'performance',
    'all-transactions',
    'ens-assets',
  ];
  //Tabs Configuration
  const tabOptionsWithCount = useMemo(() => {
    //  const { is_owner } = walletInfoState || {};
    const tabsOptions = getTabOptions(walletOverview);

    const filteredData = tabsOptions.filter((item) => {
      // Only hide the items if chain_id is not equal to 1
      if (walletOverview?.chain !== 'ethereum' && idsToHide.includes(item.id)) {
        return false;
      }
      return true;
    });

    if (authorization || (authorization && walletOverview.is_owner)) {
      return filteredData;
    } else {
      return filteredData.filter((item) => item.id !== 'performance');
    }
  }, [authorization, walletInfoState, walletOverview]);

  function onChangeTabHndlr(tabId) {
    setWalletSummaryActiveTabId(tabId);
  }
  function openChatGptModalHndlr() {
    const { is_owner } = walletInfoState || {};
    if (!authorization) {
      setShowChatModalState((prevState) => ({
        ...prevState,
        showModal: false,
        chatWalletDetails: undefined,
      }));
      setShowLoginModalState(true);
      setLoginModalTextState(
        'Sign up or sign in to access comprehensive wallet analysis powered by GPT.'
      );
      return;
    } else if (!is_owner) {
      setAddWalletModalState(true);
      return;
    }

    const chatModalInfo = {
      chain_id: chainIdFromUrl,
      nft_tx_count: walletOverview.nft_tx_count,
      nft_count: walletOverview.nft_count,
      wallet_address: walletAddress,
      chain: walletOverview.chain,
      collections: walletOverview?.collection_count || 0,
      nft: walletOverview?.nft_count || 0,
      ens: walletOverview?.ens_count || 0,
      wallet_age: walletOverview?.age ? `${ageDiff(walletOverview?.age)}` : '',
      realized_profits: walletInfoState?.realized_profit,
      buying_power: walletOverview?.ether_balance,
      latest_activity: walletOverview?.latest_transaction
        ? moment(walletOverview?.latest_transaction).format('MM/DD/YYYY h:mm A')
        : 'N/A',
      transactions_last_24_hrs: 0,
      mints_last_24_hrs: 0,
      number_of_mints: walletOverview?.mint_count || 0,
      blue_chip_collections: walletOverview?.blue_chip_count || 0,
      est_wallet_value:
        getValueWithDecimal(walletOverview?.portfolio_value) || 0,
      ...walletOverview,
      total_transactions_eth_nfts: walletOverview?.nft_tx_count,
      est_pnl: walletOverview?.pnl_value || 0,
    };
    setShowChatModalState((prevState) => ({
      ...prevState,
      showModal: true,
      chatWalletDetails: chatModalInfo,
    }));
  }
  function closeChatModalHndlr() {
    setShowChatModalState((prevState) => ({
      ...prevState,
      showModal: false,
      chatWalletDetails: undefined,
    }));
  }

  const showAuthorizeModal = useCallback(() => {
    setShowLoginModalState(true);
  }, []);

  const showSupportModal = useCallback(() => {
    setShowSupportModalState(true);
  }, []);

  function hideAuthorizeModal() {
    setShowLoginModalState(false);
  }

  //Get the Active Tab Component
  const activeComponent = useMemo(() => {
    const TabComponent = getActiveMenuComponent(walletSummaryActiveTabId);
    if (TabComponent) {
      return (
        <TabComponent
          walletInfo={walletInfoState}
          isWalletSyncing={isWalletSyncingState}
          walletCountInfo={walletOverview}
          authorizeCallback={showAuthorizeModal}
          loginModalTextCallback={setLoginModalTextState}
          supportModalCallback={showSupportModal}
          walletOverviewV2={walletOverview}
          walletOverviewDataListV2={walletOverviewDataList}
          walletOverviewChartV2={walletOverviewChart}
          chartValueV2={chartValue}
          filterWalletPortfolioChart={getWalletPortfolioChart}
        />
      );
    }
    return null;
  }, [
    walletSummaryActiveTabId,
    walletInfoState,
    isWalletSyncingState,
    showSupportModal,
    showAuthorizeModal,
    walletOverviewChart,
    walletOverviewDataList,
    walletOverview,
  ]);

  function editWalletNameHandler(event) {
    event.preventDefault();
    const { is_owner } = walletInfoState || {};
    if (!authorization) {
      event.preventDefault();
      setLoginModalTextState(
        'Sign up or sign in to assign your wallet a unique label.'
      );
      setShowLoginModalState(true);
      return;
    }
    if (!is_owner) {
      setAddWalletModalState(true);
      return;
    }
    setEditWalletState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      editName: walletInfoState?.name,
    }));
  }

  async function updateWalletNameHandler(event) {
    event.preventDefault();
    const { editName } = editWalletState;
    setEditWalletState((prevState) => ({
      ...prevState,
      isEditWalletLoadingState: true,
    }));
    updateWalletInfoApiCall(editName, walletInfoState?.hexCode);
  }

  async function updateWalletInfoApiCall(editName, hexCode = '#F9D423') {
    const { data, token } = authorization || {},
      { id: userId } = data || {};
    await apicallback(
      null,
      '/updateWalletDetailsv2',
      {
        id: walletId,
        name: editName,
        user_id: userId,
        address: walletAddress,
        chain_id: '1',
        hexCode: hexCode,
      },
      'private',
      token
    );
    setEditWalletState((prevState) => ({
      ...prevState,
      isEditWalletLoadingState: false,
      showModal: false,
    }));
    getAnalyticsApiCall();
  }

  function onChangeWalletNameHndlr(event) {
    const { value } = event.target;
    setEditWalletState((prevState) => ({ ...prevState, editName: value }));
  }

  const handleColorChange = (event) => {
    // setColor(event.target.value);
    // setHasColorChanged(true);
    setColorPickerBtnState((prevState) => ({
      ...prevState,
      currentColor: event.target.value,
      hasColorChanged: true,
    }));
  };

  const handleColorConfirm = () => {
    const { hasColorChanged, currentColor } = colorPickerBtnState;
    if (hasColorChanged) {
      const { name } = walletInfoState || {};
      updateWalletInfoApiCall(name, currentColor);
      setColorPickerBtnState((prevState) => ({
        ...prevState,
        hasColorChanged: false,
      }));
    }
  };

  function hideSupportModal() {
    setShowSupportModalState(!showSupportModalState);
  }

  function hideAddWalletModal() {
    setAddWalletModalState(false);
  }

  function subscribeWalletHndlr(e) {
    const { is_owner } = walletInfoState || {};
    if (chainIdFromUrl == 1 || chainIdFromUrl == 2) {
      if (!authorization) {
        setLoginModalTextState(
          'Sign up or sign in to subscribe and receive email alerts for wallet activity.'
        );
        setShowLoginModalState(true);
        return;
      }
      if (!is_owner) {
        setAddWalletModalState(true);
        return;
      }
      setWalletListeningState(e.target.checked);
      updateListeningWallet(e.target.checked);
    } else {
      openToasterAlert(
        'error',
        'Email Alerts only available for ETH and Polygon Chain'
      );
    }
  }

  function ageDiff(age) {
    if (age) {
      const current = moment().utc();
      const d = moment(age).utc();
      return current.diff(d, 'days') + ' Days';
    } else {
      return '-';
    }
  }
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  const walletName = useMemo(() => {
    if (authorization) {
      return authorization
        ? getDisplayValueWithDash(walletInfoState?.name)
        : 'Wallet';
    }
    return 'Wallet 1';
  }, [authorization, walletInfoState]);
  const isAuthorized = !!authorization;
  return (
    <React.Fragment>
      <PageTitle
        meta={{
          title: `${condenseAddress(walletAddress)} Wallet ${
            walletSummaryActiveTabId[0].toUpperCase() +
            walletSummaryActiveTabId.slice(1).toLowerCase()
          } | ANZALI`,
          description:
            'Track any wallet address with precision, access in-depth analytics, and leverage Chat GPT for instant answers to your wallet-related queries. Stay connected to your any wallet transactions by subscribing to wallet alerts, delivered directly to your mailbox.',
        }}
      />
      {isLoadingState && <AnzaliLoader />}
      <div className="walletinfo-container">
        <div
          style={{
            backgroundColor: isAuthorized
              ? colorPickerBtnState.currentColor
              : '#F9D423',
          }}
          className="wallet-color-container"
        >
          <div className="changecolorbtn-container">
            <div className="changecolorbtn-wrapper cursor-pointer">
              {isAuthorized && walletInfoState?.is_owner ? (
                <>
                  <input
                    type="color"
                    className="colorpicker"
                    value={colorPickerBtnState.currentColor}
                    onChange={handleColorChange}
                    onBlur={handleColorConfirm}
                  />
                  <button className="changecolor-btn">
                    <img src={bucket_icon} className="bucket-icon" />
                    Change Wallet Color
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    if (!authorization) {
                      setShowLoginModalState(true);
                      setLoginModalTextState(
                        'Sign up or sign in to assign your wallet a unique identity.'
                      );
                    } else if (authorization && !walletInfoState?.is_owner) {
                      setAddWalletModalState(true);
                    }
                  }}
                  className="changecolor-btn"
                >
                  <img src={bucket_icon} className="bucket-icon" />
                  Change Wallet Color
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="page-container">
          <div className="walletdetails-wrapper">
            <div className="walletdetails-container">
              <div className="walletbasic-container">
                <img
                  src={walletImage ? walletImage : dummy_wallet_img}
                  className="wallet-img"
                />
                <div className="d-flex flex-column mb-3 w-100">
                  <h1
                    id="walletName"
                    onClick={() => copyClipboard(walletName)}
                    className="wallet-name"
                  >
                    {walletName}
                  </h1>

                  <div className="d-flex align-items-center">
                    <span className="mr-1 font-14">
                      {condenseAddress(walletAddress)}
                    </span>
                    <img
                      onClick={() => copyClipboard(walletAddress)}
                      src={document_copy_img}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                </div>
              </div>
              <div className="header-actions mb-4">
                <a
                  onClick={editWalletNameHandler}
                  className="share-icon border-right"
                >
                  <FaUserEdit />
                </a>
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`
                    )
                  }
                  className="share-icon"
                >
                  <BsFacebook />
                </a>
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `http://www.reddit.com/submit?url=${currentPageUrl}`
                    )
                  }
                  className="share-icon"
                >
                  <AiFillRedditCircle />
                </a>
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `https://twitter.com/intent/tweet?url=${currentPageUrl}`
                    )
                  }
                  className="share-icon twitter-icon"
                >
                  <TwitterXIcon />
                </a>
                <a
                  href="#"
                  onClick={() => copyClipboard(currentPageUrl)}
                  className="share-icon"
                >
                  <AiOutlineLink />
                </a>
                {editWalletState.showModal && (
                  <form onSubmit={updateWalletNameHandler}>
                    <div className="edit-namecontainer top">
                      <input
                        type="text"
                        value={editWalletState.editName}
                        onChange={onChangeWalletNameHndlr}
                        className="editname-input"
                      />
                      <button
                        type="submit"
                        disabled={editWalletState.isEditWalletLoadingState}
                        className="update-btn"
                      >
                        {!editWalletState.isEditWalletLoadingState ? (
                          <>Update</>
                        ) : (
                          <Bars color="#8a8aa0" height={20} width={30} />
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>

          <div className="walletbalance-summary-container">
            {/* <div className="walletbalance-card">
              <h6 className="label-info">Realized Profits</h6>
              {walletOverview ? (
                <p className="value-info">
                  {getValueWithDecimal(walletOverview?.realized_profit)} ETH
                </p>
              ) : (
                <Bars
                  color="#ffffff"
                  wrapperStyle={{ padding: '5px' }}
                  height={22}
                  width={50}
                />
              )}
            </div> */}
            <div className="walletbalance-card">
              <h6 className="label-info d-flex align-items-center">
                Portfolio Value{' '}
                <Tooltip
                  id="Portfolio-tooltip"
                  place="bottom-end"
                  style={{ zIndex: '9999', width: '250px' }}
                />
                <AiOutlineInfoCircle
                  className="ml-2"
                  data-tooltip-id="Portfolio-tooltip"
                  data-tooltip-html="
                    The sum of the estimated value of the collections held by the address<br/><br/>

Estimated value of each collection = the number of NFTs the address holds × NFT estimated value of each collections<br/><br/>

For high-liquidity collections<br/>
- NFT estimated value = max(floor price, collection 7D median Price)<br/><br/>

For low-liquidity collections<br/>
- NFT estimated value = floor price<br/><br/>

For collections whose volume is 0 in latest 7 days or with few holders<br/>
- NFT estimated value = min(floor price, avg. cost of this wallet address on this collection)<br/><br/>

Suspected wash trades have been filtered out
                    "
                />
              </h6>
              {walletOverview ? (
                <p
                  className={`value-info ${
                    getValueWithDecimal(walletOverview?.portfolio_value) < 0
                      ? 'text-danger'
                      : 'text-success-dark'
                  } `}
                >
                  {getValueWithDecimal(walletOverview?.portfolio_value)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              ) : (
                <Bars
                  color="#ffffff"
                  wrapperStyle={{ padding: '5px' }}
                  height={22}
                  width={50}
                />
              )}
            </div>
            <div className="walletbalance-card">
              <h6 className="label-info">Buying Power</h6>
              {walletOverview ? (
                <p className="value-info">
                  {getValueWithDecimal(walletOverview?.ether_balance)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              ) : (
                <Bars
                  color="#ffffff"
                  wrapperStyle={{ padding: '5px' }}
                  height={22}
                  width={50}
                />
              )}
            </div>
            <div className="walletbalance-card">
              <h6 className="label-info">Chain</h6>
              <p className="value-info text-custom-primary">
                {chains[chainIdFromUrl]}
              </p>
            </div>

            <div className="walletbalance-card">
              <h6 className="d-none d-md-block label-info">
                Total Transactions (NFTs)
              </h6>
              <h6 className="d-block d-md-none label-info">Total TX (NFTs)</h6>
              {walletOverview ? (
                <p className="value-info">{walletOverview?.nft_tx_count}</p>
              ) : (
                <Bars
                  color="#ffffff"
                  wrapperStyle={{ padding: '5px' }}
                  height={22}
                  width={50}
                />
              )}
            </div>
            <div className="walletbalance-card">
              <h6 className="label-info">Wallet Age</h6>
              {walletOverview ? (
                <p className="value-info">{ageDiff(walletOverview?.age)}</p>
              ) : (
                <Bars
                  color="#ffffff"
                  wrapperStyle={{ padding: '5px' }}
                  height={22}
                  width={50}
                />
              )}
            </div>
          </div>
          <div className="alertchat-container">
            <div className="row">
              <div className="col-sm-12 mb-4 mb-md-0 col-md-6 col-lg-6 email-wrapper">
                <div className="email-container">
                  <div className="mt-2">
                    <h4 className="font-18">Email Alerts</h4>
                    <p className="d-block lh-global d-lg-none font-14 text-neutral text-neutral">
                      Tap to receive wallet activity email alerts.
                    </p>
                    <p className="d-none lh-global d-lg-block font-14 text-neutral text-neutral">
                      Click on Subscribe to receive wallet activity email
                      alerts.
                    </p>
                  </div>
                  <div>
                    <input
                      className="tgl tgl-skewed"
                      id="cb3"
                      type="checkbox"
                      checked={walletListeningState}
                      onChange={subscribeWalletHndlr}
                    />
                    <label
                      className="tgl-btn"
                      data-tg-off="Subscribe"
                      data-tg-on="Unsubscribe"
                      htmlFor="cb3"
                    ></label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="chatgpt-container"
                  onClick={openChatGptModalHndlr}
                >
                  <img
                    src={chat_gpt_icon}
                    alt="chat-gpt-icon"
                    className="img-fluid token-detail-chatgpt-icon mr-4"
                  />
                  <div className="mt-2">
                    <h4 className="font-18">AI Chat</h4>
                    <p className="font-14 lh-global text-neutral">
                      In-depth wallet analysis powered by GPT.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            containerClassName="tab-primary mb-3"
            tabOptions={tabOptionsWithCount}
            activeTabId={walletSummaryActiveTabId}
            tabChangeHndlr={onChangeTabHndlr}
          />
          <div className="tabbody-container">{activeComponent}</div>
        </div>
      </div>
      <LoginSignUp
        show={showLoginModalState}
        onHide={hideAuthorizeModal}
        text={
          loginModalTextState
            ? loginModalTextState
            : 'Sign up or sign in to see performance.'
        }
        url={false}
      />
      <SupportWarningModal
        show={showSupportModalState}
        onHide={hideSupportModal}
      />
      <AddWalletModal
        show={addWalletModalState}
        onHide={hideAddWalletModal}
        authorization={authorization}
        walletAddress={walletAddress}
      />
      {showChatModalState.showModal && (
        <ChatModal
          onClose={closeChatModalHndlr}
          summary={showChatModalState.chatWalletDetails}
          walletAddress={walletAddress}
          authorization={authorization}
          chain_id={chainIdFromUrl}
        />
      )}
    </React.Fragment>
  );
}
