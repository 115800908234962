import React, { useMemo } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import Table from '../../components/table/Table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  getValueWithDecimal,
  getTwoDigitValue,
  getPriceValue,
} from '../../utility/commonUtitlity';
import RarityCard from './RarirtyCard';
import axios from 'axios';
//Images
import { document_copy_img } from '../../assets/Index';
import { ReactComponent as TwitterXIcon } from '../../assets/images/icon/twitterx_icon.svg';
import bannerAVAX from '../../assets/images/chain_banners/AVAX.png';
import bannerETH from '../../assets/images/chain_banners/ETH.png';
import bannerMatic from '../../assets/images/chain_banners/Matic.png';
import bannerBTC from '../../assets/images/chain_banners/BTC.png';
import bannerSolona from '../../assets/images/chain_banners/Solona.png';

//components
import {
  openUrl,
  copyClipboard,
  condenseAddress,
  saveMeta,
  nftKeywords,
} from '../../callbacks/index';
import { BsFacebook } from 'react-icons/bs';
import { AiFillRedditCircle, AiOutlineLink } from 'react-icons/ai';
import { useState } from 'react';
import { generateColumns } from './helper';
import AnzaliLoader from '../../components/AnzaliLoader';
import PageTitle from '../../components/pagetitle/PageTitle';
//Api Payload
import { frame_view_icon } from '../../assets/Index';
import { useEffect } from 'react';
import { noImageFound, ens_white } from '../../assets/Index';
import NoDataComponent from '../../components/NoDataComponent';
import { toast } from 'react-toastify';
import Zoom from '../../components/zoomImage/Zoom';
function getCardImage(isEnsItem) {
  if (isEnsItem) {
    return ens_white;
  } else {
    return noImageFound;
  }
}
function replaceImage(error) {
  //replacement of broken Image
  error.target.src = noImageFound;
}

export default function ItemInfo() {
  const currentPageUrl = window.location.href;
  const [isLoading, setIsLoading] = useState(false);
  const {
    address: walletAddress,
    id: nftId,
    chain: chainIdFromUrl,
  } = useParams();
  const [searchParams] = useSearchParams();

  const [traitsState, setTraitsState] = useState({});
  const [isOpen, setisOpen] = useState(false);
  const isEnsItem =
    walletAddress.toLowerCase() ==
    '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'.toLowerCase();
  ////V2
  const [nftDetailCounts, setNftDetailCounts] = useState(null);
  const [nftDetails, setNftDetails] = useState(null);
  const [rarityDetail, setRarityDetail] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    if (walletAddress && nftId) {
      if (walletAddress || nftId == undefined) {
        getItemInfoApiCall();
      } else {
        history('/');
      }
    } else {
      history('/');
    }
  }, [walletAddress, nftId]);

  async function getNftTraits(traits = [], totalSupply = 1) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_ENDPOINT_V2}/getCollectionTraits?address=${walletAddress}&chain=${chainIdFromUrl}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;

        const result = [];
        traits.forEach((item) => {
          const trait = item.trait_type;
          const value = item.value;
          if (res.counts[trait]) {
            result.push({
              name: trait,
              value: res.counts[trait][value],
              rarity: (res.counts[trait][value] / totalSupply) * 100,
            });
          }
        });
        setTraitsState(result);
      }
    } catch (error) {}
  }

  async function getItemInfoApiCall() {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_NODE_ENDPOINT_V2}/getNFTDetails?address=${walletAddress}&id=${nftId}&chain=${chainIdFromUrl}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;
        setNftDetailCounts(res?.counts);
        setNftDetails(res?.nft_details);
        setRarityDetail(res?.rarity_details);
        setTransactions(res?.sales_details);
        if (res.nft_details) {
          if (chainIdFromUrl == 1) {
            await getNftTraits(
              res?.nft_details?.raw?.metadata?.attributes || [],
              res?.nft_details?.contract?.totalSupply || 1
            );
          } else {
            let result = [];
            res?.nft_details?.raw?.metadata?.attributes.forEach((item) => {
              const trait = item.type;
              const value = item.value;
              result.push({
                name: trait,
                value: value,
                rarity: null,
              });
            });
            setTraitsState(result);
          }
          saveMeta({
            title:
              res.nft_details.name + ' NFT | ANZALI'
                ? res?.nft_details?.collection?.name +
                  ' #' +
                  res?.nft_details?.tokenId +
                  ' NFT | ANZALI'
                : '',
            description: res.nft_details.name
              ? res?.nft_details?.collection?.name +
                ' #' +
                res?.nft_details?.tokenId
              : '',
            images:
              res?.nft_details?.image?.cachedUrl ||
              res?.nft_details?.collection?.bannerImageUrl,
            url: `/item-info/${walletAddress}/${nftId}${
              searchParams ? `?artblock=${searchParams.get('artblock')}` : ''
            }`,
            file: 'nfts',
            keywords: `${
              res.nft_details.name
                ? res?.nft_details?.collection?.name +
                  ' #' +
                  res?.nft_details?.tokenId
                : ''
            },NFT Collection ANZALI, NFT Collection,ANZALI NFT Collection,${nftKeywords()}`,
          });
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

      // const payload = {
      //     contract_address: walletAddress,
      //     nft_id: nftId,
      //     artblock: searchParams.get('artblock'),
      //   },
      //   { token } = authorization || {};
      // const response = await axios.post(
      //   `${process.env.REACT_APP_NODE_ENDPOINT}/nft_details`,
      //   payload,
      //   {
      //     headers: authorization
      //       ? {
      //           'content-type': 'application/json',
      //           Authorization: `Token ${token}`,
      //         }
      //       : null,
      //   }
      // );
      // if (response.status === 200) {
      //   const { data } = response,
      //     { counts, details } = data || {};
      //   const imagesInfo = getEnsImages(isEnsItem, details),
      //     metricsInfo = counts,
      //     nameDetails = getEnsDisplayName(details);
      //   const transactionData = getTransactions(details);
      //   const traitsData = getTraits(details);
      //   const rarity = {
      //     ...details?.rartiy,
      //     collection_total_supply: details?.collection_total_supply,
      //   };
      //   setTransactionState(transactionData);
      //   setTraitsState(traitsData);
      //   const updatedNftInfo = {
      //     ...nameDetails,
      //     walletAddress,
      //     metricsInfo,
      //     rarity: rarity,
      //     ...imagesInfo,
      //   };
      //   if (updatedNftInfo.ensNameTitle) {
      //     saveMeta({
      //       title: updatedNftInfo?.ensNameTitle + ' NFT | ANZALI' || null,
      //       description: updatedNftInfo?.ensName || null,
      //       images: updatedNftInfo?.cardImage || null,
      //       url: `/item-info/${walletAddress}/${nftId}${
      //         searchParams ? `?artblock=${searchParams.get('artblock')}` : ''
      //       }`,
      //       file: 'nfts',
      //       keywords: `${
      //         updatedNftInfo?.ensNameTitle
      //       },NFT Collection ANZALI, NFT Collection,ANZALI NFT Collection,${nftKeywords()}`,
      //     });
      //   }
      //   setNftInfoState(updatedNftInfo);
      // }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  }

  const chainLink = {
    1: 'https://etherscan.io/tx/',
    2: 'https://polygonscan.com/tx/',
    3: 'https://btcscan.org/tx/',
    4: 'https://solscan.io/tx/',
    5: 'https://avascan.info/blockchain/shrapnel/tx/',
  };

  function redirectHandler(event) {
    const transactionHash = event.currentTarget.getAttribute('data-id');
    if (transactionHash) {
      openUrl(`${chainLink[chainIdFromUrl]}${transactionHash}`);
    }
  }

  const menuOptions = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: redirectHandler,
      },
    ];
  }, []);

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }
  const chainList = {
    0: 'All',
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  const columns = useMemo(() =>
    generateColumns(
      walletAddress,
      copyAddressHandler,
      menuOptions,
      chainIdFromUrl
    )
  );

  const chains = {
    1: bannerETH,
    2: bannerMatic,
    3: bannerBTC,
    4: bannerSolona,
    5: bannerAVAX,
  };

  return (
    <React.Fragment>
      {isLoading && <AnzaliLoader />}
      <PageTitle
        meta={{
          title: `${nftDetails?.name || ''} NFT | ANZALI`,
        }}
      />
      <section className="iteminfo-container">
        <div
          className={classNames(
            'headerimg-container',
            isEnsItem ? '' : 'custom-img'
          )}
          style={
            isEnsItem
              ? {
                  background:
                    'linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%)',
                }
              : {
                  backgroundImage: `url(${
                    nftDetails?.collection?.bannerImageUrl ||
                    chains[chainIdFromUrl]
                  })`,
                }
          }
        ></div>
        <Zoom
          isOpen={isOpen}
          images={[nftDetails?.image?.cachedUrl || getCardImage(isEnsItem)]}
          photoIndex={0}
          setIsOpen={setisOpen}
        ></Zoom>
        {nftDetails ? (
          <div className="page-container">
            <div className="walletdetails-wrapper mb-5">
              <div className="walletdetails-container">
                <div className="walletbasic-container">
                  <img
                    onError={replaceImage}
                    src={
                      nftDetails.image.cachedUrl
                        ? nftDetails.image.cachedUrl
                        : nftDetails.image.originalUrl ||
                          getCardImage(isEnsItem)
                    }
                    className="wallet-img border"
                    onClick={setisOpen}
                  />
                  <div className="d-flex flex-column mb-3 w-100">
                    <h1
                      id="walletName"
                      onClick={() => copyClipboard(nftDetails?.name)}
                      className="wallet-name"
                    >
                      {nftDetails?.name
                        ? nftDetails?.name
                        : nftDetails?.collection?.name}{' '}
                    </h1>
                    <ReactTooltip
                      anchorSelect={`#walletName`}
                      place="bottom"
                      className="tooltip-wrapper"
                      classNameArrow="tooltip-arrow"
                      offset={2}
                      openOnClick={true}
                      content={nftDetails?.name}
                    />
                    <div className="d-flex align-items-center">
                      <span className="mr-1 font-14">
                        {condenseAddress(walletAddress)}
                      </span>
                      <img
                        onClick={() => copyClipboard(walletAddress)}
                        src={document_copy_img}
                        style={{ width: '20px', height: '20px' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="header-actions mb-4">
                  <a
                    href="#"
                    onClick={() =>
                      openUrl(
                        `https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}&t=${nftDetails?.name}`
                      )
                    }
                    className="share-icon"
                  >
                    <BsFacebook />
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      openUrl(
                        `http://www.reddit.com/submit?url=${currentPageUrl}&title=${nftDetails?.name}`
                      )
                    }
                    className="share-icon"
                  >
                    <AiFillRedditCircle />
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      openUrl(
                        `https://twitter.com/intent/tweet?url=${currentPageUrl}&text=${nftDetails?.name}`
                      )
                    }
                    className="share-icon twitter-icon"
                  >
                    <TwitterXIcon />
                  </a>
                  <a
                    href="#"
                    onClick={() => copyClipboard(currentPageUrl)}
                    className="share-icon"
                  >
                    <AiOutlineLink />
                  </a>
                </div>
              </div>
            </div>
            <div className="section-detail">
              <div className="title-container">
                <h4>Key Metrics</h4>
              </div>
              <div className="body-container">
                <div className="row row-gap-md labelinfo-cardcontainer mb-4">
                  <div className="col-6 col-lg-2 column-gap-md">
                    <div className="labelinfo-card">
                      <h6 className="label-info">
                        Last Sale {chainList[chainIdFromUrl]}
                      </h6>
                      <p className="value-info">
                        {nftDetailCounts?.last_sale_price
                          ? Number(nftDetailCounts?.last_sale_price).toFixed(6)
                          : ''}{' '}
                        {chainList[chainIdFromUrl]}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-2 column-gap-md">
                    <div className="labelinfo-card">
                      <h6 className="label-info">Last Sale Date</h6>
                      <p className="value-info">
                        {/* 22 Sept, 2023 */}
                        {nftDetailCounts?.last_sale_date
                          ? moment(nftDetailCounts?.last_sale_date)
                              .utc()
                              .format('DD MMM, YYYY')
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-2 column-gap-md">
                    <div className="labelinfo-card">
                      <h6 className="label-info">Current Hold Time</h6>
                      <p className="value-info">
                        {nftDetailCounts?.holding_time || 0} Days
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-2 column-gap-md">
                    <div className="labelinfo-card">
                      <h6 className="label-info">Number Times Sold</h6>
                      <p className="value-info">
                        {nftDetailCounts?.times_sold
                          ? getTwoDigitValue(nftDetailCounts?.times_sold)
                          : nftDetailCounts?.times_sold}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!isEnsItem && (
              <div className="section-detail">
                <div className="title-container">
                  <h4>Rarity Details</h4>
                </div>
                <div className="body-container">
                  <div className="row row-gap-md">
                    <div className="col-12 col-lg-3 col-gap-md  border-lg-right">
                      <div className="raritycard-container">
                        <RarityCard
                          tokenTitle="Bored Ape #0"
                          tokenImage={nftDetails?.image?.cachedUrl}
                          displayName={
                            nftDetails?.name
                              ? nftDetails?.name
                              : nftDetails?.collection?.name
                          }
                          displayTitle={
                            nftDetails?.name
                              ? nftDetails?.name
                              : nftDetails?.collection?.name
                          }
                          // supplyValue={1}
                          // totalSupply={100}
                          supplyValue={rarityDetail?.rank}
                          totalSupply={nftDetails?.contract?.totalSupply || 1}
                          // showPriceInfo={collection_info_status === 1}
                          showPriceInfo={true}
                          hideVerified={false}
                          floorPrice={getValueWithDecimal()}
                          volumeTraded={getValueWithDecimal()}
                          redirectLink={`/collection-info/${''}`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-8 col-gap-md">
                      {Array.isArray(traitsState) && traitsState.length > 0 ? (
                        <div className="row row-gap-md labelinfo-cardcontainer">
                          {traitsState.map((item, index) => {
                            return (
                              <div
                                key={`traits-${index}`}
                                className="col-6 col-lg-3 column-gap-md"
                              >
                                <div className="rarity-card">
                                  <div className="card-title">{item.name}</div>
                                  <div className="card-body">
                                    <div
                                      style={{ overflow: 'hidden' }}
                                      className="d-flex justify-content-center w-100"
                                    >
                                      <div
                                        className="btn-rarity"
                                        title={item.value}
                                      >
                                        {item.value}
                                      </div>
                                    </div>
                                    {item.rarity
                                      ? Math.ceil(item.rarity) + '%'
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="row row-gap-md labelinfo-cardcontainer h-100">
                          <div className="col-12 d-flex align-items-center justify-content-center">
                            <NoDataComponent title="No traits found." />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="section-detail">
              <div className="title-container">
                <h4>Transactions</h4>
              </div>
              <div className="body-container">
                <Table
                  columns={columns}
                  data={transactions}
                  isDataLoading={isLoading}
                  loadingMessage="Loading Audit..."
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </section>
    </React.Fragment>
  );
}
