import React, { useMemo, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import Table from '../../../components/table/Table';
import AssetCard from '../../../components/card/assetscard';
import LoadingCard from '../../../components/card/LoadingCard';
//components
import AreaChart from '../../../components/Chart/AreaChart';
import { generateColumns } from './helper';
import { BsArrowRightShort } from '../../../components/icons/Index';
import { openUrl } from '../../../callbacks/index';
//Utitliy
import { getTwoDigitValue } from '../../../utility/commonUtitlity';
import { noImageFound } from '../../../assets/Index';
//API
import classNames from 'classnames';
import NoDataComponent from '../../../components/NoDataComponent';
import { getValueWithDecimal } from '../../../utility/commonUtitlity';
import { AiOutlineInfoCircle } from 'react-icons/ai';

//Store
import { Tooltip } from 'react-tooltip';

function replaceImage(error) {
  //replacement of broken Image
  error.target.src = noImageFound;
}
export default function Overview({
  walletInfo,
  chartValueV2,
  walletOverviewV2,
  walletOverviewDataListV2,
  walletOverviewChartV2,
  filterWalletPortfolioChart,
}) {
  const {
    id: walletId,
    wallet_address: walletAddress,
    chain_id: chainIdFromUrl,
  } = useParams();
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [recentTransactionState, setRecentTransactionState] = useState(null);

  const authorization = useStoreState((state) => state.authorization);
  const setWalletSummaryActiveTabId = useStoreActions(
    (actions) => actions.setWalletSummaryActiveTabId
  );

  const { total_event_count } = walletInfo || {},
    showSupportWallet =
      (!isNaN(total_event_count) && Number(total_event_count) > 25000) || false;

  const navigate = useNavigate();

  useEffect(() => {
    //  if (overviewTabData) {
    // const totalCollectionData = getTotalCollectionData(overviewTabData),
    //   totalNftData = getTotalNFTData(overviewTabData),
    //   ensCollectionData = getENSCollectionData(overviewTabData),
    //   recentTransactionData = getRecentTransactionData(overviewTabData);
    // setTotalCollectionState(totalCollectionData);
    // setTotalNftState(totalNftData);
    // setEnsCollectionState(ensCollectionData);
    setRecentTransactionState(
      walletOverviewDataListV2?.latest_transaction_list
    );
    setIsLoadingState(false);
    // }
  }, [walletOverviewDataListV2, walletOverviewV2, walletOverviewChartV2]);

  useEffect(() => {
    // async function getRealizedChartData() {
    //   try {
    //     setILoadingChartState(true);
    //     const response = await apicallback(
    //       null,
    //       '/getWalletStats',
    //       { address: walletAddress },
    //       'private',
    //       authorization.token
    //     );
    //     if (response.status === 'success') {
    //       const { data } = response,
    //         { est_chart, profit_chart } = data || {};
    //       if (Array.isArray(profit_chart)) {
    //         const categories = [],
    //           data = [];
    //         profit_chart.forEach((item) => {
    //           const { date, value } = item,
    //             updatedDate = moment(date).utc().format('MMM DD, YYYY'),
    //             updatedValue = getValueWithDecimal(value);
    //           categories.push(updatedDate);
    //           data.push(updatedValue);
    //         });
    //         setRealizedChartState((prevState) => ({
    //           ...prevState,
    //           categories,
    //           chartData: [
    //             {
    //               name: 'Profit',
    //               data: data,
    //             },
    //           ],
    //         }));
    //       }
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     setILoadingChartState(false);
    //   }
    // }
    // const { loading, total_event_count } = walletInfo || {},
    //   showSupportWallet =
    //     (!isNaN(total_event_count) && Number(total_event_count) > 25000) ||
    //     false;
    // if (walletInfo && !loading && !showSupportWallet && authorization) {
    //   getRealizedChartData();
    // }
  }, [walletInfo, authorization]);

  function viewMoreBtnHandler(event) {
    const dataId = event.currentTarget.getAttribute('data-id');
    if (dataId) {
      setWalletSummaryActiveTabId(dataId);
    }
  }

  function redirectHandler(event) {
    const address = event.currentTarget.getAttribute('data-id');
    if (address) {
      navigate(`/collection-info/${address}/${chainIdFromUrl}`);
    }
  }

  function redirectNftHandler(contractAddress, trackerId) {
    if (contractAddress && trackerId) {
      navigate(`/item-info/${contractAddress}/${trackerId}/${chainIdFromUrl}`);
    }
  }

  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  const mintingColumns = useMemo(
    () => generateColumns(walletAddress, chainIdFromUrl),
    []
  );
  return (
    <div className="walletoverview-container">
      <div className="row custom-row">
        <div className="col-12 col-lg-7 custom-column mb-4">
          <div className="row custom-row blanaceinfo-cardcontainer mb-4">
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  PNL Value{' '}
                  <Tooltip
                    id="pnl-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="pnl-tooltip"
                    data-tooltip-html="
                    Profit and loss is the sum of realized profit made from sales and estimated unrealized profit of NFTs held by the address
                    <br/><br/>Suspected wash trades have been filtered out"
                  />
                </h6>

                <p
                  className={`value-info ${
                    getValueWithDecimal(walletOverviewV2?.pnl_value) < 0
                      ? 'text-danger'
                      : 'text-success-dark'
                  } `}
                >
                  {getValueWithDecimal(walletOverviewV2?.pnl_value)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Unrealized Profit{' '}
                  <Tooltip
                    id="Unrealized-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Unrealized-tooltip"
                    data-tooltip-html="
                    Estimated unrealized profit of NFTs held by the address<br/><br/>
Individual NFT unrealized profit = NFT estimated value - this NFT's cost<br/><br/>
The total unrealized profit is obtained by summing up the individual NFT unrealized profit
<br/><br/>
ERC-1155: Unrealized profit = (NFT estimated value - NFT cost) * token quantity
Suspected wash trades have been filtered out"
                  />
                </h6>

                <p
                  className={`value-info ${
                    getValueWithDecimal(walletOverviewV2?.unrealized_profit) < 0
                      ? 'text-danger'
                      : 'text-success-dark'
                  } `}
                >
                  {getValueWithDecimal(walletOverviewV2?.unrealized_profit)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Realized Profit{' '}
                  <Tooltip
                    id="Realized-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Realized-tooltip"
                    data-tooltip-html="
                    Profit made from sales by the address<br/><br/>
                    Individual NFT realized profit = Sale price - Price of most recent transaction before sale<br/><br/>
                    The total realized profit is obtained by summing up all the individual NFT realized profit
<br/><br/>
For ERC-1155 token:
Realized profit = (Most recent sale price - Previous sale price) * Most recent number of sales
<br/><br/>
Suspected wash trades have been filtered out
"
                  />
                </h6>

                <p
                  className={`value-info ${
                    getValueWithDecimal(walletOverviewV2?.realized_profit) < 0
                      ? 'text-danger'
                      : 'text-success-dark'
                  } `}
                >
                  {getValueWithDecimal(walletOverviewV2?.realized_profit)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Bought{' '}
                  <Tooltip
                    id="Bought-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Bought-tooltip"
                    data-tooltip-html="The number of buy activities generated by the address over the selected time range"
                  />
                </h6>
                <p className="value-info">
                  {walletOverviewV2?.buy_num ? walletOverviewV2?.buy_num : 0}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Sold{' '}
                  <Tooltip
                    id="Sold-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Sold-tooltip"
                    data-tooltip-html="The number of sell activities of each collection made by the address"
                  />
                </h6>
                <p className="value-info">
                  {walletOverviewV2?.sell_num ? walletOverviewV2?.sell_num : 0}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Number Of Mints{' '}
                  <Tooltip
                    id="Mints-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Mints-tooltip"
                    data-tooltip-html="The number of mint activities generated by the address over the selected time range"
                  />
                </h6>
                <p className="value-info">
                  {walletOverviewV2?.mint_count
                    ? walletOverviewV2?.mint_count
                    : 0}
                </p>
              </div>
            </div>

            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Total Revenue{' '}
                  <Tooltip
                    id="Revenue-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Revenue-tooltip"
                    data-tooltip-html="Total money the address gained by selling NFTs<br/><br/>
                    Suspected wash trades have been filtered out"
                  />
                </h6>

                <p
                  className={`value-info ${
                    getValueWithDecimal(walletOverviewV2?.total_revenue) < 0
                      ? 'text-danger'
                      : 'text-success-dark'
                  } `}
                >
                  {getValueWithDecimal(walletOverviewV2?.total_revenue)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              </div>
            </div>

            <div className="col-6 col-lg-4 custom-column cursor-pointer">
              <div className="balanceinfo-card">
                <h6 className="label-info">
                  Total Spent{' '}
                  <Tooltip
                    id="Sold-tooltip"
                    place="bottom-end"
                    style={{ zIndex: '9999', width: '250px' }}
                  />
                  <AiOutlineInfoCircle
                    data-tooltip-id="Sold-tooltip"
                    data-tooltip-html="Total money the address spent buying and minting NFTs<br/><br/>
                    Suspected wash trades have been filtered out"
                  />
                </h6>
                <p className={`value-info text-danger`}>
                  {getValueWithDecimal(walletOverviewV2?.total_spent)}{' '}
                  {chains[chainIdFromUrl]}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4 custom-column">
              <div className="balanceinfo-card">
                <h6 className="label-info">Latest Activity</h6>
                <p className="value-info">
                  {moment(walletOverviewV2?.latest_transaction).format(
                    'MMM DD, YYYY'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={classNames('chart-container')}>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-2">Portfolio Chart</h5>
                  <div className="chart-filter">
                    {['24h', '7d', '30d'].map((value, key) => (
                      <span
                        className={`text-uppercase ${
                          chartValueV2 === value ? 'text-main' : 'text-light'
                        }`}
                        key={key}
                        onClick={() => filterWalletPortfolioChart(value)}
                      >
                        {value}
                      </span>
                    ))}
                  </div>
                </div>
                {walletOverviewChartV2 == null ? (
                  <div className="d-flex flex-column justify-content-center align-items-center min-h-350px">
                    {chainIdFromUrl == 1 ? (
                      <>
                        <Bars
                          color="#8a8aa0"
                          wrapperClass="mb-3"
                          height={30}
                          width={30}
                        />
                        <span className="mb-2 font-16">Loading...</span>
                      </>
                    ) : (
                      <span className="mb-2 font-16 text-center">
                        This feature is available for Ethereum chain only.
                        <br />
                        We are working on it.
                        <br /> Soon it will work for every defined chain.
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    <AreaChart
                      title="Portfolio Chart"
                      categories={walletOverviewChartV2.x}
                      data={[
                        {
                          name: 'Portfolio Value',
                          data: walletOverviewChartV2.y,
                        },
                      ]}
                      time={true}
                      filter={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 custom-column mb-4">
          <div className="recenttransaction-container">
            <h5 className="mb-2">Recent Transactions</h5>
            <>
              {recentTransactionState ? (
                <>
                  <Table
                    columns={mintingColumns}
                    data={recentTransactionState}
                    isDataLoading={isLoadingState}
                    divClassName="mb-4"
                    loadingMessage="Loading Recent Transactions."
                  />
                  <div className="text-center">
                    <button
                      className="sc-button style-4 text-dark"
                      id="try-now"
                      data-id="nft-transactions"
                      onClick={viewMoreBtnHandler}
                    >
                      View More
                    </button>
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          </div>
        </div>
      </div>

      <div className="row custom-row">
        {chainIdFromUrl == 1 ? (
          <div className="col-12 col-lg-7 custom-column mb-4">
            <div className="ens-container">
              <h5 className="mb-4">ENS Collections</h5>
              <>
                {walletOverviewDataListV2 == null ? (
                  <div className="row custom-row">
                    {Array(3)
                      .fill(0)
                      .map((item, index) => (
                        <div
                          key={`feature-token=${index}`}
                          className="col-6 col-lg-4 custom-column"
                        >
                          <LoadingCard />
                        </div>
                      ))}
                  </div>
                ) : (
                  <>
                    {Array.isArray(walletOverviewDataListV2.ens) &&
                    walletOverviewDataListV2.ens.length > 0 ? (
                      <React.Fragment>
                        <div className="row custom-row">
                          {walletOverviewDataListV2.ens.map((item, index) => {
                            const { collection, contract } = item || {},
                              { stats } = collection || {};
                            return (
                              <div
                                key={`feature-token=${index}`}
                                className="col-6 col-lg-4 custom-column"
                              >
                                <AssetCard
                                  tokenTitle={item?.name}
                                  tokenId={item?.tokenId}
                                  assetImage={
                                    item.image.originalUrl
                                      ? item?.image?.originalUrl
                                      : item?.collection?.bannerImageUrl ||
                                        noImageFound
                                  }
                                  circulatingValue={
                                    contract?.openSeaMetadata?.floorPrice
                                      ? getValueWithDecimal(
                                          contract?.openSeaMetadata?.floorPrice
                                        )
                                      : ''
                                  }
                                  is_last_sold={false}
                                  contractAddress={contract?.address}
                                  redirectLink={`/item-info/${contract.address}/${item.tokenId}/${chainIdFromUrl}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="text-center">
                          <button
                            className="sc-button style-4 text-dark"
                            id="try-now"
                            data-id="ens-assets"
                            onClick={viewMoreBtnHandler}
                          >
                            View More
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <NoDataComponent
                        title="No ENS Collection Owned by This Wallet"
                        subTitle="The Ethereum Name Service (ENS) is a distributed,
                                    open, and extensible naming system based on the
                                    Ethereum blockchain."
                      >
                        <Link
                          onClick={() => openUrl(`https://ens.domains/`)}
                          to="#"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                          rel="noopener noreferrer"
                        >
                          <button
                            className="sc-button d-flex style-4 text-dark mt-4"
                            id="verify-on-etherscan"
                          >
                            Buy ENS Name
                            <BsArrowRightShort className="font-20 ml-1 fw-bold" />
                          </button>
                        </Link>
                      </NoDataComponent>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        ) : (
          ''
        )}
        <div
          className={`${
            chainIdFromUrl == 1 ? 'col-12 col-lg-5' : 'col-12'
          } custom-column mb-4`}
        >
          <div className="collectionnft-container">
            <div className="innercard-container mb-4">
              <h6 className="mb-4">Collections</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h1>{getTwoDigitValue(walletOverviewV2?.collection_count)}</h1>
                <div className="avatar-group">
                  {Array.isArray(walletOverviewDataListV2?.walletCollections) &&
                    walletOverviewDataListV2?.walletCollections.map(
                      (item, index) => (
                        <div
                          key={`collection-images-${index}`}
                          className="avatar-group-item"
                        >
                          <img
                            data-id={item?.address}
                            onClick={redirectHandler}
                            onError={replaceImage}
                            src={
                              item.image.thumbnailUrl
                                ? item.image.thumbnailUrl
                                : item.image.originalUrl
                                ? item.image.originalUrl
                                : item?.bannerImageUrl || noImageFound
                            }
                            alt=""
                            className="rounded-circle avatar-sm"
                          />
                        </div>
                      )
                    )}
                  {Array.isArray(walletOverviewDataListV2?.walletCollections) &&
                    walletOverviewDataListV2?.walletCollections.length >= 4 && (
                      <div
                        data-id="collections"
                        onClick={viewMoreBtnHandler}
                        className="avatar-group-item"
                      >
                        <div className="rounded-circle avatar-sm number-container">
                          {Array.isArray(
                            walletOverviewDataListV2?.walletCollections
                          ) &&
                            walletOverviewDataListV2?.walletCollections.length}
                          +
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="innercard-container mb-4">
              <h6 className="mb-4">NFTs</h6>
              <div className="d-flex flex-column justify-content-between">
                <h1 className="mb-2">
                  {getTwoDigitValue(walletOverviewV2?.nft_count)}
                </h1>
                <div className="row row-gap-xs">
                  <>
                    {Array.isArray(walletOverviewDataListV2?.walletNFTs) &&
                      walletOverviewDataListV2?.walletNFTs.map(
                        (item, index) => (
                          <div
                            key={`collection-images-${index}`}
                            className="column-gap-xs col-6 col-md-3"
                          >
                            <div className="total-nftimage-container">
                              <img
                                onClick={() =>
                                  redirectNftHandler(
                                    item?.contract?.address,
                                    item?.tokenId
                                  )
                                }
                                src={
                                  item.image.thumbnailUrl
                                    ? item.image.thumbnailUrl
                                    : item.image.originalUrl
                                    ? item.image.originalUrl
                                    : item?.collection?.bannerImageUrl ||
                                      noImageFound
                                }
                                onError={replaceImage}
                                alt=""
                                className="total-nft-image"
                              />
                            </div>
                          </div>
                        )
                      )}
                    {Array.isArray(walletOverviewDataListV2?.walletNFTs) &&
                      walletOverviewDataListV2?.walletNFTs.length > 0 && (
                        <div className="col-12 column-gap-xs mt-3 d-flex justify-content-center">
                          <button
                            className="sc-button style-4 text-dark"
                            id="try-now"
                            data-id="assets"
                            onClick={viewMoreBtnHandler}
                          >
                            View NFTs
                          </button>
                        </div>
                      )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
