import React, { Fragment, useEffect, useState } from 'react';
import TokenCard from '../../components/card/tokencard';
import { numFormatter } from '../../callbacks/index';
import { collectionsMultichain } from '../../apis/Index';
import PageTitle from '../../components/pagetitle/PageTitle';
//Utility
import { getValueWithDecimal } from '../../utility/commonUtitlity';
import LoadingCard from '../../components/card/LoadingCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';

const Index = () => {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getCollectionsApiCallback();
  }, []);

  async function getCollectionsApiCallback() {
    const response = await collectionsMultichain();
    if (response && response.status == 'success') {
      setlist(response.data);
      setLoader(false);
    }
  }

  const chains = {
    1: 'ETH COLLECTIONS',
    2: 'MATIC COLLECTIONS',
    3: 'BTC COLLECTIONS',
    4: 'SOL COLLECTIONS',
    5: 'AVAX COLLECTIONS',
  };

  return (
    <Fragment>
      <section className="tf-section tf-rank">
        <PageTitle
          meta={{
            title:
              'Explore NFT Collections With In-Depth Data and Analytics | ANZALI',
            description:
              'Dive deep into collections and individual NFTs. Explore endless data, charts, floor prices, and rarity scores. Make informed decisions about the NFTs you love.',
          }}
        />
        <div className="page-container mb-4">
          <div className="top-seller">
            {loader && (
              <div className="row">
                {Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <div className="col-sm-3 mb-3" key={index}>
                      <div
                        className="card-container"
                        key={`dummy-feature-token=${index}`}
                      >
                        <LoadingCard />
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {Object.entries(list).map(([chain, collections]) => (
              <>
                {collections.length ? (
                  <div className="row" key={chain}>
                    <div className="col-md-12">
                      <div className="heading-live-auctions">
                        <h4 className="tf-title text-uppercase">
                          {chains[collections[0].chain]}
                        </h4>
                        <Link
                          to={`/view-collections/${collections[0].chain}`}
                          className="exp style2"
                        >
                          View All
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12 mb-0">
                      <Swiper
                        modules={[Navigation, Scrollbar, A11y]}
                        spaceBetween={30}
                        slidesPerView={8}
                        navigation
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                          },
                          767: {
                            slidesPerView: 2,
                          },
                          991: {
                            slidesPerView: 4,
                          },
                          1200: {
                            slidesPerView: 8,
                          },
                          1350: {
                            slidesPerView: 8,
                          },
                        }}
                        scrollbar={{ draggable: true }}
                      >
                        {collections.map((collection) => (
                          <SwiperSlide key={collection.id}>
                            <TokenCard
                              tokenTitle={collection.name}
                              tokenImage={collection.image}
                              showPriceInfo={false}
                              floorPrice={
                                collection.floor_price
                                  ? getValueWithDecimal(collection.floor_price)
                                  : null
                              }
                              volumeTraded={
                                collection?.volume_traded
                                  ? numFormatter(
                                      collection?.volume_traded,
                                      false
                                    )
                                  : null
                              }
                              redirectLink={`/collection-info/${collection.address}/${collection.chain}`}
                              itemDetails={collection}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Index;
