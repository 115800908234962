import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import {
  getDisplayValueWithDash,
  getValueWithDecimal,
} from '../../utility/commonUtitlity';

export default function SplineChart({
  title = 'Title',
  data,
  categories,
  containerClassName = '',
  time = false,
  epoc = true,
  filterChartDataByChain,
  filter = true,
}) {
  const [chainId, setChainId] = useState('0');
  const chains = {
    0: 'All (USD)',
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };
  const chartDat = {
    series: data || [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        colors: ['#A162F7'],
        width: 1.5,
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: 'category',
        categories: categories || [],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#B1B1B1',
            fontSize: '14px',
          },
          formatter: function (value) {
            return time
              ? epoc
                ? moment(value).utc().format('MMM DD, YYYY')
                : moment.unix(value).utc().format('MMM DD, YYYY')
              : value;
          },
        },
        title: {
          text: '',
          style: {
            color: '#B1B1B1',
            fontSize: '14px',
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#B1B1B1',
            fontSize: '14px',
          },
          formatter: function (value) {
            return value ? getValueWithDecimal(value) : 0;
          },
        },
        title: {
          text: title,
          style: {
            color: '#B1B1B1',
            fontSize: '14px',
          },
        },
      },
      markers: {
        colors: ['#A162F7'],
      },
      noData: {
        text: 'No data available',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#ffffff',
          fontSize: '14px',
        },
      },
      grid: {
        strokeDashArray: 8,
        position: 'back',
        borderColor: '#272B30',
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        x: {
          show: false,
        },
        theme: 'dark',
        style: {},
        marker: {
          fillColors: ['#A162F7'],
        },
      },
      fill: {
        // colors: ["#A162F7"],
        colors: ['#A162F7'],
        opacity: 0.1,
        type: 'solid',
      },
    },
  };
  return (
    <div id="spline-chart" className={containerClassName}>
      {filter ? (
        <div className="mt-0 d-flex align-items-start justify-content-around w-100 chain-list">
          {Object.entries(chains).map(([k, v]) => (
            <span
              key={k}
              className={`${chainId == k ? 'active' : ''}`}
              onClick={() => {
                setChainId(k);
                filterChartDataByChain(k);
              }}
            >
              {v}
            </span>
          ))}
        </div>
      ) : (
        ''
      )}
      <ReactApexChart
        chart={chartDat.chart}
        options={chartDat.options}
        series={chartDat.series}
        type="area"
        height={350}
      />
    </div>
  );
}
