import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from '../../../components/table/Table';
import Timeline from './Timeline';
import { AiOutlineUnorderedList, AiOutlineFieldTime } from 'react-icons/ai';
import { Bars } from 'react-loader-spinner';
import { generateColumns } from './helper';
import { copyClipboard, openUrl } from '../../../callbacks/index';
//Api Payload
import { frame_view_icon } from '../../../assets/Index';

export default function Transactions({ walletInfo }) {
  const { wallet_address: walletAddress, chain_id: chainIdFromUrl } =
    useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isList, setIsList] = useState(true);
  ///V2
  const [transactionStateV2, setTransactionStateV2] = useState([]);
  const [transactionOffsetStateV2, setTransactionOffsetStateV2] =
    useState(null);
  const [noDataFoundStateV2, setNoDataFoundStateV2] = useState(false);

  useEffect(() => {
    if (walletAddress) {
      getTransactionsApiCall();
    }
  }, [walletAddress]);

  async function getTransactionsApiCall(cursor = null) {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NODE_ENDPOINT_V2
        }/getNftEvents?address=${walletAddress}${
          cursor ? `&cursor=${cursor}` : ''
        }&chain=${chainIdFromUrl}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;
        if (res.transactions.length === 0 && transactionStateV2.length === 0) {
          setNoDataFoundStateV2(true);
        }
        setTransactionStateV2([...transactionStateV2, ...res.transactions]);
        setTransactionOffsetStateV2(res.next_cursor);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  function loadMoreHandler() {
    getTransactionsApiCall(transactionOffsetStateV2);
  }

  const chainLink = {
    1: 'https://etherscan.io/tx/',
    2: 'https://polygonscan.com/tx/',
    3: 'https://btcscan.org/tx/',
    4: 'https://solscan.io/tx/',
    5: 'https://avascan.info/blockchain/shrapnel/tx/',
  };

  function redirectHandler(event) {
    const transactionHash = event.currentTarget.getAttribute('data-id');
    if (transactionHash) {
      openUrl(`${chainLink[chainIdFromUrl]}${transactionHash}`);
    }
  }

  const menuOptions = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: redirectHandler,
      },
    ];
  }, [transactionStateV2]);

  const transactionsColumns = useMemo(
    () =>
      generateColumns(
        walletAddress,
        copyAddressHandler,
        menuOptions,
        chainIdFromUrl
      ),
    [transactionStateV2]
  );

  const isTableDataLoading =
    Array.isArray(transactionStateV2) && transactionStateV2.length === 0
      ? isLoading
      : false;

  const handelToggle = () => {
    setIsList(isList ? false : true);
  };

  return (
    <div className="walletcollections-container">
      <div className="row">
        <div className="col-12">
          <div className="tabbody-headercontainer">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h4 className="mb-0">Transactions</h4>
              {transactionStateV2.length > 0 ? (
                <div className="toggle-ui">
                  <AiOutlineUnorderedList
                    className={isList ? `toggle-active` : ''}
                    onClick={handelToggle}
                  />
                  <AiOutlineFieldTime
                    className={!isList ? `toggle-active` : ''}
                    onClick={handelToggle}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {isList ? (
          <div className="col-12">
            <Table
              columns={transactionsColumns}
              data={Array.isArray(transactionStateV2) ? transactionStateV2 : []}
              isDataLoading={isTableDataLoading}
              loadingMessage="Loading Transactions."
            />
          </div>
        ) : (
          <div className="col-12">
            <Timeline
              columns={transactionsColumns}
              data={Array.isArray(transactionStateV2) ? transactionStateV2 : []}
              isDataLoading={isTableDataLoading}
              loadingMessage="Loading Transactions."
            />
          </div>
        )}
        <div className="col-12 mt-3 d-flex justify-content-center">
          {transactionOffsetStateV2 ? (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
