import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LoadingCard from '../../components/card/LoadingCard';
import { Bars } from 'react-loader-spinner';
import TokenCard from '../../components/card/tokencard';
import NoDataComponent from '../../components/NoDataComponent';
import { getDisplayValueWithDash } from '../../utility/commonUtitlity';
import { toast } from 'react-toastify';
import { getValueWithDecimal } from '../../utility/commonUtitlity';
import { noImageFound } from '../../assets/Index';
//Collection Tab UI.
export default function Collections({ walletInfo }) {
  const { wallet_address: walletAddress, chain_id: chainIdFromUrl } =
    useParams();
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    action: '',
  });
 
  ////V2
  const [collectionsStateV2, setCollectionsStateV2] = useState([]);
  const [collectionsOffsetStateV2, setCollectionsOffsetStateV2] =
    useState(null);
  const [noDataFoundStateV2, setNoDataFoundStateV2] = useState(false);
  useEffect(() => {
    if (walletAddress) {
      getCollectionsApiCall();
    }
  }, [walletAddress]);

  async function getCollectionsApiCall(cursor = null) {
    setLoadingState({
      isLoading: true,
    });
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NODE_ENDPOINT_V2
        }/getCollectionswithOpensea?address=${walletAddress}${
          cursor ? `&pagekey=${cursor}` : ''
        }&chain=${chainIdFromUrl}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;
        if (res.contracts.length === 0 && collectionsStateV2.length === 0) {
          setNoDataFoundStateV2(true);
        }
        setCollectionsStateV2([...collectionsStateV2, ...res.contracts]);
        setCollectionsOffsetStateV2(res.pageKey);
      }
    } catch (error) {
      toast.error('Something went wrong in collections.');
    } finally {
      setLoadingState({
        isLoading: false,
        action: '',
      });
    }
  }

  function loadMoreHandler() {
    getCollectionsApiCall(collectionsOffsetStateV2);
  }
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };
  const isloadMoreLoading = false;
  return (
    <div className="walletcollections-container">
      <div className="row">
        <div className="col-12">
          <div className="tabbody-headercontainer">
            <div className="d-flex align-items-center justify-content-between ">
              <h4 className="mb-0">Collections</h4>
            </div>
            {/* <div className="searchwallet-container">
              <input
                type="text"
                id="searchString"
                name="searchString"
                className="search-input size-small"
                value={filterState.searchString}
                onChange={onSearchHandler}
                placeholder="Search Collection"
                disabled={showNoData}
              />
              <button className="searching-btn-container">
                {isSearchLoading ? (
                  <Bars color="#ffffff" height={20} width={20} />
                ) : (
                  <FiSearch />
                )}
              </button>
            </div> */}
          </div>
        </div>
        <div className="col-12">
          <div className="feature-card-container">
            {collectionsStateV2.map((item, index) => {
              const { image, openSeaMetadata, description, address } = item;
              let imgUrl = null;
              if (image) {
                imgUrl = image.originalUrl
                  ? image.originalUrl
                  : image.cachedUrl
                  ? image.cachedUrl
                  : openSeaMetadata?.imageUrl || noImageFound;
              }
              return (
                <div key={`feature-token=${index}`} className="card-container">
                  <TokenCard
                    isTwentyFourHourVolume={true}
                    tokenTitle={getDisplayValueWithDash(item?.name)}
                    tokenImage={imgUrl}
                    // showPriceInfo={collection_info_status === 1}
                    isTotalSupply={chainIdFromUrl == 1 ? false : true}
                    totalSupply={item?.totalSupply || 0}
                    showPriceInfo={true}
                    hideVerified={!item?.isSpam}
                    floorPrice={
                      openSeaMetadata?.floorPrice >= 0
                        ? getValueWithDecimal(openSeaMetadata.floorPrice)
                        : ''
                    }
                    volumeTraded={item.numDistinctTokensOwned}
                    redirectLink={`/collection-info/${address}/${chainIdFromUrl}`}
                    itemDetails={description}
                    chain={chainIdFromUrl}
                    chainId={chainIdFromUrl}
                  />
                </div>
              );
            })}
            {loadingState.isLoading &&
              Array(5)
                .fill(0)
                .map((item, index) => (
                  <div
                    key={`dummy-feature-token=${index}`}
                    className="card-container"
                  >
                    <LoadingCard />
                  </div>
                ))}

            {noDataFoundStateV2 && (
              <NoDataComponent title="No Collections Found."></NoDataComponent>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 d-flex justify-content-center">
          {collectionsOffsetStateV2 ? (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isloadMoreLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
