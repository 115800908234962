import React, { useEffect, useMemo, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Modal } from 'react-bootstrap';
import { ReactComponent as ThunmbsIcon } from '../../assets/images/airdrops/thumbs.svg';
import { BsFacebook } from 'react-icons/bs';
import { share_icon } from '../../assets/Index';
import { AiFillRedditCircle, AiFillInfoCircle } from 'react-icons/ai';
import { ReactComponent as TwitterXIcon } from '../../assets/images/icon/twitterx_icon.svg';
import { ReactComponent as WebsiteIcon } from '../../assets/images/airdrops/website.svg';

import AnzaliLoader from '../../components/AnzaliLoader';
//API Actions
import {
  apicallback,
  copyClipboard,
  openUrl,
  saveMeta,
} from '../../callbacks/index';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { cross_close_icon } from './../../assets/Index';
import { noImageFound } from '../../assets/Index';
import { toast } from 'react-toastify';
import LoginSignUp from '../../components/models/LoginSignUp';
import { TooltipIconClick } from './TooltipIconClick';
import PageTitle from '../../components/pagetitle/PageTitle';

const socialIcons = {
  twitter: <TwitterXIcon />,
  website: <WebsiteIcon />,
  facebook: <BsFacebook />,
  reddit: <AiFillRedditCircle />,
};
export default function AirdropOverview() {
  const { airdropSlug } = useParams();
  const navigate = useNavigate();
  const authorization = useStoreState((state) => state.authorization);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [airdropDataState, setAirdropDataState] = useState(null);
  const [activeSectionId, setActiveSectionId] = useState('introduction');
  const [showLoginModalState, setShowLoginModalState] = useState(false);
  const [showModalState, setShowModalState] = useState({
    showModal: false,
    hybridTaskIcon: null,
    hybridTaskTitle: '',
    hybridTasks: [],
  });
  const {
    id: airdropId,
    supported_tokens,
    socials,
    banner_color,
    outer_image,
    tasks = [],
    startgeys = [],
  } = airdropDataState || {};

  useEffect(() => {
    if (airdropSlug) {
      setIsLoadingState(true);
      getAirdropApiCall(airdropSlug);
    }
  }, [airdropSlug]);

  async function getAirdropApiCall(slug) {
    const response = await apicallback(
      null,
      `/getContentbySlug?slug=${slug}`,
      null,
      null,
      null,
      'get'
    );
    if (response.status === 'success') {
      saveMeta({
        title: response?.data?.meta_title + '' || null,
        description: response?.data?.meta_description || null,
        images: response?.data?.meta_image || null,
        url: `/airdrops/${response?.data?.slug}`,
        file: 'airdrops',
        keywords: `${response?.data?.meta_title}`,
      });
      setAirdropDataState(response?.data);
    } else if (response.status === 'fail') {
      navigate('/404');
    }
    setIsLoadingState(false);
  }

  function clickSectionHandler(event) {
    const sectionId = event.currentTarget.getAttribute('data-id');
    if (sectionId) {
      const sectionDivId = document.getElementById(sectionId);
      if (!sectionDivId) {
        return;
      }
      setActiveSectionId(sectionId);
      window.scrollTo({
        behavior: 'smooth',
        top:
          sectionDivId.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          100,
      });
    }
  }

  function viewStrategyHndlr() {
    const sectionDivId = document.getElementById('stragety');
    if (!sectionDivId) {
      return;
    }
    setActiveSectionId('stragety');
    window.scrollTo({
      behavior: 'smooth',
      top:
        sectionDivId.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        100,
    });
  }

  async function onLikeClickHandler() {
    if (!authorization?.token) {
      setShowLoginModalState(true);
      return;
    }
    const response = await apicallback(
      null,
      '/toogleContentLike',
      {
        content_id: airdropId,
      },
      'private',
      authorization.token
    );
    if (response.status === 'success') {
      //   toast.success("Airdrop like updated successfully.");
      getAirdropApiCall(airdropSlug);
    } else if (response.status === 'fail') {
      toast.error('Something went wrong.');
    }
  }

  const supportTokensInfo = useMemo(() => {
    if (Array.isArray(supported_tokens) && supported_tokens.length > 0) {
      const chainsData = supported_tokens.reduce((obj, data) => {
        const { chain_name } = data || {},
          chainNameKey = chain_name?.toString().toLowerCase();
        if (obj[chainNameKey]) {
          obj[chainNameKey]?.chainData?.push(data);
        } else {
          obj[chainNameKey] = {
            chain_name,
            chainData: [data],
          };
        }
        return obj;
      }, {});
      return Object.values(chainsData);
    }
    return [];
  }, [supported_tokens]);

  async function viewHybridTaskHndlr(item) {
    const response = await apicallback(
      null,
      `/getAllStratgeyTaskbyid`,
      null,
      null,
      null,
      'get',
      {
        params: {
          id: item?.id,
        },
      }
    );
    if (response?.status === 'success') {
      const { data } = response;
      setShowModalState((prevState) => ({
        ...prevState,
        showModal: true,
        hybridTaskTitle: item?.text,
        hybridTaskIcon: item?.image,
        hybridTasks: Array.isArray(data) ? data : [],
      }));
    } else {
      toast.error('Something went wrong.');
    }
  }

  function closeModalHndlr() {
    setShowModalState((prevState) => ({ ...prevState, showModal: false }));
  }
  const baseLocation = window.location.origin;
  return (
    <React.Fragment>
      {isLoadingState && <AnzaliLoader />}
      <PageTitle
        meta={{
          title: airdropDataState?.meta_title,
          description: airdropDataState?.meta_description,
          image: airdropDataState?.meta_image,
        }}
      />
      <div className="airdropoverview-container">
        <div
          style={{ backgroundColor: banner_color || '#FFC7C7' }}
          className="airdropcolor-container"
        ></div>
        <div className="page-container">
          <div className="airdropdetails-wrapper">
            <div className="airdropdetails-container">
              <div className="airdropbasic-container">
                <img
                  src={outer_image || noImageFound}
                  className="airdrop-img"
                />
                <div className="airdopbasicdetails flex-column mb-3 w-100">
                  <h1
                    id="airdropName"
                    onClick={null}
                    className="airdrop-name mb-1"
                  >
                    {airdropDataState?.name}
                  </h1>
                  <div className="d-flex align-items-center text-neutral">
                    <span className="mr-1 font-14">
                      {airdropDataState?.Subtitle}
                    </span>
                  </div>
                </div>
              </div>

              <div className="header-actions">
                <div>
                  <button className="likebtn" onClick={onLikeClickHandler}>
                    <ThunmbsIcon height="20px" width="20px" className="mr-1" />
                    {airdropDataState?.likes || '0'} Likes
                  </button>
                </div>

                {Array.isArray(socials) && socials.length > 0 && (
                  <div className="sharebutton-actions">
                    {socials.map((item, index) => {
                      const { name } = item,
                        keyName = name?.toString()?.toLowerCase();
                      return (
                        <a
                          href={item?.url}
                          onClick={null}
                          key={`sociallinks-${index}`}
                          className={classNames('share-icon', {
                            'twitter-icon': keyName === 'twitter',
                            'website-icon': keyName === 'website',
                          })}
                          target="_blank"
                        >
                          {socialIcons[keyName]}
                        </a>
                      );
                    })}
                  </div>
                )}

                <div>
                  <button
                    className="sharebtn"
                    onClick={() => {
                      copyClipboard(`${baseLocation}/airdrops/${airdropSlug}`);
                    }}
                  >
                    <img src={share_icon} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileairdropdetails">
            <div className="mb-3">
              <h1 id="airdropName" onClick={null} className="airdrop-name mb-1">
                {airdropDataState?.name}
              </h1>
              <div className="d-flex align-items-center text-neutral">
                <span className="mr-1 font-14">
                  {airdropDataState?.Subtitle}
                </span>
              </div>
            </div>
            <button className="likebtn" onClick={onLikeClickHandler}>
              <ThunmbsIcon height="20px" width="20px" className="mr-1" />
              {airdropDataState?.likes || '0'} Likes
            </button>
          </div>
          <div className="mb-5">
            <div className="row custom-row">
              <div className="col-12 col-lg-9 custom-column">
                <section id="introduction">
                  <div className="mb-5">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h2 className="font-24 font-bold mb-0">Overview</h2>
                      <button
                        onClick={viewStrategyHndlr}
                        className="sc-button py-8 px-14 rounded-lg style-4 text-dark"
                      >
                        View Tasks
                      </button>
                    </div>
                    <div className="row custom-row infocards-container">
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              {!airdropDataState?.estimated_rewards
                                ? 'Announced Rewards'
                                : 'Estimated Rewards'}
                            </h6>
                            <TooltipIconClick
                              iconId="rewards-tooltip"
                              toolTipName={
                                airdropDataState?.estimated_rewards
                                  ? 'Indicates any incentives or returns offered to users. <br/> Estimated by our experts, actual amounts may vary.'
                                  : 'Indicates any incentives or returns offered to users.'
                              }
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="rewards-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p
                            className={classNames(
                              'font-24 font-bold truncate',
                              { 'text-green': airdropDataState?.rewards }
                            )}
                          >
                            {airdropDataState?.rewards
                              ? `${airdropDataState?.rewards}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">Type</h6>
                            <TooltipIconClick
                              iconId="type-tooltip"
                              toolTipName="Describes the category or class of the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="type-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.Category || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              Funding
                            </h6>
                            <TooltipIconClick
                              iconId="funding-tooltip"
                              toolTipName="The amount of money raised by the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="funding-tooltip"
                              />
                            </TooltipIconClick>
                          </div>

                          <p className="font-24 font-bold">
                            {airdropDataState?.fundings
                              ? `$${airdropDataState?.fundings}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              Total value locked
                            </h6>
                            <TooltipIconClick
                              iconId="totalvaluelocked-tooltip"
                              toolTipName="The total value of assets locked or staked in the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="totalvaluelocked-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold">
                            {/* ${airdropDataState?.total_value_locked || '-'} */}
                            {airdropDataState?.total_value_locked
                              ? `$${airdropDataState?.total_value_locked}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              Unique Users
                            </h6>
                            <TooltipIconClick
                              iconId="uniqueusers-tooltip"
                              toolTipName="The total number of distinct individuals using the protocol."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="uniqueusers-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.unique_stakers || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards h-100">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              Chains
                            </h6>
                            <TooltipIconClick
                              iconId="chains-tooltip"
                              toolTipName="The blockchain networks supported by the project (e.g., ETH or BTC)"
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="chains-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <div className="d-flex gap-1 align-items-center">
                            {Array.isArray(airdropDataState?.chains) &&
                            airdropDataState.chains.length > 0
                              ? airdropDataState?.chains.map((item) => (
                                  <>
                                    <img
                                      key={`chain-${item.id}`}
                                      style={{ height: '24px', width: '24px' }}
                                      src={item.image}
                                      title={item.name}
                                    />
                                  </>
                                ))
                              : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">Grade</h6>
                            <TooltipIconClick
                              iconId="grade-tooltip"
                              toolTipName="An evaluation or rating of the project based on quality and performance of the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="grade-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.grade || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">Phase</h6>
                            <TooltipIconClick
                              iconId="phase-tooltip"
                              toolTipName="The current development stage of the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="phase-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.phase || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">
                              Structure
                            </h6>
                            <TooltipIconClick
                              iconId="structure-tooltip"
                              toolTipName="The operational and organizational framework of the project to reward users."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="structure-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.structure || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">Gas</h6>
                            <TooltipIconClick
                              iconId="structure-tooltip"
                              toolTipName="Indicates transaction fees that are required to complete the process."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="structure-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold">
                            {airdropDataState?.gas ? 'Yes' : 'No'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">TGE</h6>
                            <TooltipIconClick
                              iconId="tge-tooltip"
                              toolTipName="Information about Token Generation Event, which is when a project's tokens were created and distributed."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="tge-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.tge || '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 custom-column">
                        <div className="infocards">
                          <div className="d-flex justify-content-center align-items-center  mb-2">
                            <h6 className="font-14 text-neutral mr-1">Tasks</h6>
                            <TooltipIconClick
                              iconId="tasks-tooltip"
                              toolTipName="The number of activities available for users to complete within the project."
                            >
                              <AiFillInfoCircle
                                className="font-14 text-neutral"
                                id="tasks-tooltip"
                              />
                            </TooltipIconClick>
                          </div>
                          <p className="font-24 font-bold truncate">
                            {airdropDataState?.tasks?.length || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="introduction">
                  <div className="mb-5">
                    <h2 className="font-24 font-bold mb-3">Introduction</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: airdropDataState?.introduction || '',
                      }}
                      className="dynamic-section"
                    ></div>
                  </div>
                </section>
                <section id="stragety">
                  <div className="mb-5">
                    <h2 className="font-24 font-bold mb-4">Tasks</h2>
                    <div className="strategies-container fs-14">
                      {Array.isArray(tasks) &&
                        tasks.map((item, index) => {
                          return (
                            <div
                              key={`task-${index}`}
                              className="strategycard bg-charletsongreen rounded-lg mb-3"
                            >
                              <div className="iconcontainer rounded-lg border-2">
                                <img src={item.image} />
                              </div>
                              <div className="descriptioncontainer">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                  style={{ fontSize: '16px' ,fontWeight:"500"}}
                                />

                                <div>
                                  <button
                                    onClick={() => {
                                      openUrl(item?.url);
                                    }}
                                    className="custom-button py-8 px-16 border-btn"
                                  >
                                    {item?.button_text || 'Complete Now'}
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </section>
                {!!airdropDataState?.fundamentals && (
                  <section id="fundamentals">
                    <div className="mb-5">
                      <h2 className="font-24 font-bold mb-4">Fundamentals</h2>
                      <div
                        className="fundamentals-section"
                        dangerouslySetInnerHTML={{
                          __html: airdropDataState?.fundamentals || '',
                        }}
                      ></div>
                    </div>
                  </section>
                )}
                <section id="potential">
                  <div className="mb-5">
                    <h2 className="font-24 font-bold mb-4">
                      Potential Rewards
                    </h2>
                    <div
                      className="fundamentals-section mb-3"
                      dangerouslySetInnerHTML={{
                        __html: airdropDataState?.potential_rewards || '',
                      }}
                    ></div>
                    <div className="row custom-row">
                      {!!airdropDataState?.investors && (
                        <div className="col-12 col-lg-4 custom-column mb-3">
                          <div className="basiccard-container h-100 rounded-lg">
                            <div className="cardheader">
                              <h2 className="font-18 font-semibold">
                                Investors
                              </h2>
                            </div>
                            <div className="cardbody">
                              <div
                                className="fundamentals-section"
                                dangerouslySetInnerHTML={{
                                  __html: airdropDataState?.investors || '',
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!airdropDataState?.audit && (
                        <div className="col-12 col-lg-4 custom-column mb-3">
                          <div className="basiccard-container h-100 rounded-lg">
                            <div className="cardheader">
                              <h2 className="font-18 font-semibold">Audit</h2>
                            </div>
                            <div className="cardbody">
                              <div
                                className="fundamentals-section"
                                dangerouslySetInnerHTML={{
                                  __html: airdropDataState?.audit || '',
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                {!!airdropDataState?.restaking_methods && (
                  <section id="restaking">
                    <div className="mb-5">
                      <h2 className="font-24 font-bold mb-4">
                        Restaking Methods
                      </h2>
                      <div
                        className="fundamentals-section"
                        dangerouslySetInnerHTML={{
                          __html: airdropDataState?.restaking_methods || '',
                        }}
                      ></div>
                    </div>
                  </section>
                )}
                {Array.isArray(supportTokensInfo) &&
                  supportTokensInfo.length > 0 && (
                    <section id="supporting-tokens">
                      <div className="mb-5">
                        <h2 className="font-24 font-bold mb-4">
                          Supporting Tokens
                        </h2>
                        <div className="row custom-row">
                          {supportTokensInfo.map((item, index) => {
                            const { chainData = [] } = item;
                            return (
                              <div
                                key={`supporttokens-${index}`}
                                className="col-12 custom-column mb-4"
                              >
                                <div className="basiccard-container h-100 rounded-lg">
                                  <div className="cardheader">
                                    <h3 className="font-18 font-semibold">
                                      {item.chain_name}
                                    </h3>
                                  </div>
                                  <div className="cardbody">
                                    <div className="row custom-row">
                                      {Array.isArray(chainData) &&
                                        chainData.map((chainItem, index) => {
                                          return (
                                            <div
                                              key={`supportingchain-${index}`}
                                              className="col-12 col-lg-4 custom-column borderlight-right mb-3"
                                            >
                                              <h3
                                                style={{ padding: '0 30px' }}
                                                className="font-18 mb-2"
                                              >
                                                {chainItem?.category}
                                              </h3>
                                              <div
                                                className="supportingtokens"
                                                dangerouslySetInnerHTML={{
                                                  __html: chainItem?.tokens,
                                                }}
                                              ></div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  )}
                {Array.isArray(startgeys) && startgeys.length > 0 && (
                  <section id="hybrid-stratey">
                    <div className="mb-5">
                      <h2 className="font-24 font-bold mb-4">
                        Hybrid Strategy
                      </h2>
                      <div className="strategies-container fs-14">
                        {startgeys.map((item, index) => {
                          return (
                            <div
                              key={`task-${index}`}
                              className="strategycard bg-charletsongreen rounded-lg mb-3"
                            >
                              <div className="iconcontainer rounded-lg border-2">
                                <img src={item.image} />
                              </div>
                              <div className="descriptioncontainer">
                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                  {item?.text}
                                </p>
                                <div>
                                  <button
                                    className="custom-button py-8 px-16 border-btn"
                                    onClick={() => {
                                      viewHybridTaskHndlr(item);
                                    }}
                                  >
                                    View Task
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}
                {!!airdropDataState?.features && (
                  <section id="feautures">
                    <div className="mb-5">
                      <h2 className="font-24 font-bold mb-4">Features</h2>
                      <div
                        className="fundamentals-section"
                        dangerouslySetInnerHTML={{
                          __html: airdropDataState?.features || '',
                        }}
                      ></div>
                    </div>
                  </section>
                )}

                <section id="nonfinancial">
                  <div className="mb-3">
                    <h2 className="font-24 font-bold mb-4">
                      Non-Financial Advice
                    </h2>
                    <div className="fundamentals-section">
                      <ul>
                        <li>
                          <b className="text-white">Research:</b>&nbsp; Explore
                          project's documentation, audits, and community.
                        </li>
                        <li>
                          <b className="text-white">Understand risks:</b>&nbsp;
                          DeFi has vulnerabilities, market volatility, and token
                          fluctuation.
                        </li>
                        <li>
                          <b className="text-white">Invest wisely:</b>&nbsp;
                          Only invest what you can afford to lose.
                        </li>
                        <li>
                          <b className="text-white">Beware of scams:</b>&nbsp;
                          Don't trust unsolicited outreach or guaranteed
                          returns.
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <p className="font-semibold mt-0">
                  <span className="text-danger">*</span>
                  <span className="text-neutral font-16">
                    ANZALI only provides suggestions and not financial advice.
                    Use all of the information above at your own risk.
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-3 d-none d-lg-block custom-column">
                <div className="basiccard-container rounded-lg">
                  <div className="cardheader">
                    <h3 className="font-16 font-semibold">Table of Contents</h3>
                  </div>
                  <div className="cardbody">
                    <div className="listsections">
                      <ul className="cont">
                        <li
                          data-id="introduction"
                          className={classNames(
                            activeSectionId === 'introduction' ? 'active' : ''
                          )}
                          onClick={clickSectionHandler}
                        >
                          Introduction
                        </li>
                        <li
                          data-id="stragety"
                          className={classNames(
                            activeSectionId === 'stragety' ? 'active' : ''
                          )}
                          onClick={clickSectionHandler}
                        >
                          Tasks
                        </li>
                        {!!airdropDataState?.fundamentals && (
                          <li
                            data-id="fundamentals"
                            className={classNames(
                              activeSectionId === 'fundamentals' ? 'active' : ''
                            )}
                            onClick={clickSectionHandler}
                          >
                            Fundamentals
                          </li>
                        )}
                        <li
                          data-id="potential"
                          className={classNames(
                            activeSectionId === 'potential' ? 'active' : ''
                          )}
                          onClick={clickSectionHandler}
                        >
                          Potential Rewards
                        </li>
                        {/* <li data-id="investor"
                                                    className={classNames(activeSectionId === "investor" ? "active" : "")}
                                                    onClick={clickSectionHandler}>Investors & Audits</li> */}
                        {!!airdropDataState?.restaking_methods && (
                          <li
                            data-id="restaking"
                            className={classNames(
                              activeSectionId === 'restaking' ? 'active' : ''
                            )}
                            onClick={clickSectionHandler}
                          >
                            Restaking Methods
                          </li>
                        )}
                        {Array.isArray(supportTokensInfo) &&
                          supportTokensInfo.length > 0 && (
                            <li
                              data-id="supporting-tokens"
                              className={classNames(
                                activeSectionId === 'supporting-tokens'
                                  ? 'active'
                                  : ''
                              )}
                              onClick={clickSectionHandler}
                            >
                              Supporting Tokens
                            </li>
                          )}
                        {Array.isArray(startgeys) && startgeys.length > 0 && (
                          <li
                            data-id="hybrid-stratey"
                            className={classNames(
                              activeSectionId === 'hybrid-stratey'
                                ? 'active'
                                : ''
                            )}
                            onClick={clickSectionHandler}
                          >
                            Hybrid Strategy
                          </li>
                        )}
                        {!!airdropDataState?.features && (
                          <li
                            data-id="feautures"
                            className={classNames(
                              activeSectionId === 'feautures' ? 'active' : ''
                            )}
                            onClick={clickSectionHandler}
                          >
                            Features
                          </li>
                        )}
                        <li
                          data-id="nonfinancial"
                          className={classNames(
                            activeSectionId === 'nonfinancial' ? 'active' : ''
                          )}
                          onClick={clickSectionHandler}
                        >
                          Non-Financial Advice
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalState.showModal}
        onHide={closeModalHndlr}
        size="lg"
        centered
        dialogClassName="hybridtask-modal"
        className="hybridtask-modal-container"
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="hybridtaskmodal-header">
          <div className="d-flex align-items-center">
            <div className="iconcontainer rounded-lg border-2">
              <img src={showModalState.hybridTaskIcon} />
            </div>
            {showModalState.hybridTaskTitle}
          </div>
          <img src={cross_close_icon} onClick={closeModalHndlr} />
        </Modal.Header>
        <Modal.Body className="hybridtaskmodal-body">
          {Array.isArray(showModalState.hybridTasks) &&
            showModalState.hybridTasks?.map((item, index) => {
              return (
                <div
                  key={`hybridtaskdata-${index}`}
                  className="strategycard bg-hybridtask rounded-lg mb-3"
                >
                  <div className="iconcontainer rounded-lg border-2">
                    <img src={item.image} />
                  </div>
                  <div className="descriptioncontainer">
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item?.text}</p>
                    <div>
                      <button
                        onClick={() => {
                          openUrl(item?.url);
                        }}
                        className="custom-button py-8 px-16 border-btn"
                      >
                        {item?.button_text || 'Complete Now'}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </Modal.Body>
      </Modal>
      <LoginSignUp
        show={showLoginModalState}
        onHide={() => {
          setShowLoginModalState(false);
        }}
        text={'Sign up or sign in to like the airdrop project.'}
        url={false}
      />
    </React.Fragment>
  );
}
