import React from 'react';
import { mintImageDefault, noImageFound } from '../../../assets/Index';
import classNames from 'classnames';
import moment from 'moment';
import {
  getDisplayValueWithNA,
  getTwoDigitValue,
  getTransactionClassName,
  getTransactionStatus,
} from '../../../utility/commonUtitlity';
import { condenseAddress } from '../../../callbacks/index';
import { document_copy_img } from '../../../assets/Index';
import { Link } from 'react-router-dom';

function replaceImage(error) {
  error.target.src = noImageFound;
}

function WalletInfo({ walletImg, title, subTitle }) {
  return (
    <div className="asset-details">
      <img
        src={walletImg || noImageFound}
        className="avatar-img"
        onError={replaceImage}
      />
      <div className="name-container">
        <span title={title} className="fw-bold title">
          {title}
        </span>
        {subTitle && (
          <span title={subTitle} className="subtitle">
            #{subTitle}
          </span>
        )}
      </div>
    </div>
  );
}

export function MenuOptions(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={item.onClickHndlr}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}

export function generateColumns(
  walletAddress,
  copyClipboard,
  menuOptions = [],
  chainIdFromUrl
) {
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'name',
      Header: 'NFT Name',
      accessor: 'name',
      columnClassName: 'w-300px max-w-300px ',
      Cell: ({ row: { original } }) => {
        const { nft } = original;
        return (
          <Link
            to={`/item-info/${nft?.contract_address}/${nft?.token_id}/${chainIdFromUrl}`}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <WalletInfo
              walletImg={nft?.image || noImageFound}
              title={getDisplayValueWithNA(nft?.name)}
              subTitle={nft?.token_id}
            />
          </Link>
        );
      },
    },
    {
      id: 'type',
      Header: 'Action',
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const { event, receiver, sender } = original;

        let statusText = getTransactionStatus(
            event,
            walletAddress,
            sender?.address || '',
            receiver?.addres || ''
          ),
          className = getTransactionClassName(statusText);
        return (
          <div className="d-flex">
            <span
              className={classNames(
                'transaction-label text-uppercase',
                className
              )}
            >
              {statusText}
            </span>
          </div>
        );
      },
    },
    {
      id: 'price',
      Header: 'Price',
      accessor: 'price',
      disableSortBy: false,
      Cell: ({ row: { original } }) => {
        const { price } = original;
        return (
          <span className="fw-bold">
            <span className="mr-1">{getTwoDigitValue(price?.value)}</span>
            <span className="text-muted">{chains[chainIdFromUrl]}</span>
          </span>
        );
      },
    },
    // {
    //   id: 'tx_hash',
    //   Header: 'Block',
    //   accessor: 'tx_hash',
    //   disableSortBy: true,
    //   Cell: ({ row: { original } }) => {
    //     const { tx_hash } = original;
    //     return (
    //       <div
    //         className="d-flex align-items-center"
    //         data-id={tx_hash}
    //         onClick={copyClipboard}
    //       >
    //         <span className="mr-1">{condenseAddress(tx_hash)}</span>
    //         <img
    //           src={document_copy_img}
    //           style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      id: 'fromAddress',
      Header: 'From Address',
      accessor: 'fromAddress',
      disableSortBy: true,
      Cell: ({ row: { index, original } }) => {
        const { sender } = original;
        return (
          <div
            className="d-flex align-items-center"
            data-id={sender.address}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(sender.address)}</span>
            {sender.address ? (
              <img
                src={document_copy_img}
                style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
              />
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      id: 'toAddress',
      Header: 'To Address',
      accessor: 'toAddress',
      disableSortBy: true,
      Cell: ({ row: { index, original } }) => {
        const { receiver } = original;
        return receiver ? (
          <div
            className="d-flex align-items-center"
            data-id={receiver?.address}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(receiver?.address)}</span>
            {receiver?.address ? (
              <img
                src={document_copy_img}
                style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
              />
            ) : (
              '-'
            )}
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      id: 'time',
      Header: 'Date',
      accessor: 'time',
      disableSortBy: true,
      columnClassName: 'w-150px min-w-150px',
      Cell: ({ value }) => {
        const dateInfo = value
            ? `${moment
                .unix(value)
                .utc()
                .format('MMM DD, YYYY | hh:mm A')} +UTC`
            : 'n/a',
          timeInfo = `${moment.unix(value).utc().fromNow(true)} ago`;
        const capitalizedTimeFromNow = value
          ? timeInfo.charAt(0).toUpperCase() + timeInfo.slice(1)
          : 'n/a';
        return (
          <div className="d-flex flex-column text-nowrap max-w-none">
            <span className="lh-title">{capitalizedTimeFromNow}</span>
            <span className="font-14 lh-title text-muted">{dateInfo}</span>
          </div>
        );
      },
    },
    {
      id: 'menuActions',
      Header: 'Actions',
      accessor: 'menuActions',
      disableSortBy: true,
      columnClassName: 'w-120px min-w-120px',
      Cell: ({ row: { original } }) => {
        const { tx_hash } = original;
        return tx_hash ? (
          <MenuOptions rowId={tx_hash} actionOptions={menuOptions} />
        ) : (
          '-'
        );
      },
    },
  ];
}
