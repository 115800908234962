import Table from '../../components/table/Table';
import React, { useMemo, useState, useEffect } from 'react';
import {
  generateColumnsCollection,
  generateColumnsTrendingCollection,
} from './helper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { topCollection } from '../../apis/Index';
import { apicallback } from '../../callbacks/index';
import { useNavigate } from 'react-router-dom';
export default function Section3() {
  const list = useStoreState((state) => state.topcol);
  const setlist = useStoreActions((actions) => actions.settopcol);
  const setcollection_info_status = useStoreActions(
    (actions) => actions.setCollectionTopStatus
  );
  const [toggle, setToggle] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [chainId, setChainId] = useState(1);

  const [collections, setTrendingCollection] = useState({
    data: [],
    pageInfo: null,
    loader: false,
  });

  useEffect(() => {
    handelAction(true, chainId);
  }, []);

  function handelAction(type, chain) {
    setToggle(type);
    setIsDataLoading(true);
    if (type) {
      getTrendingCollection(chain);
    } else {
      getTopCollections();
    }
  }

  const getTopCollections = async () => {
    let response = null;
    response = await topCollection();
    if (response && response.status == 'success') {
      setcollection_info_status(response.collection_info_status);
      setlist(response.data);
      setIsDataLoading(false);
    }
  };

  const getTrendingCollection = async (chain) => {
    const response = await apicallback(
      null,
      `/getTrendingCollectionsMultichain?chain=${chain}`,
      {},
      'private',
      null,
      'get'
    );
    console.log(response);
    if (response.status == 'success') {
      const res = response;
      setIsDataLoading(false);
      setTrendingCollection({
        data: res.data,
        pageInfo: null,
        loader: false,
      });
    }
    return;
  };

  const mintingColumns = useMemo(() => generateColumnsCollection(), []);
  const trendingColumns = useMemo(
    () => generateColumnsTrendingCollection(),
    []
  );
  const history = useNavigate();
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
  };

  return (
    <section className="section-3 pt-5">
      <div className="home-custom-container">
        <div className="head d-flex justify-content-between">
          <h4>Collections</h4>
          <div className="d-md-flex d-block">
            <div className="toggle">
              <span
                className={toggle ? 'toggle-active' : ''}
                onClick={() => handelAction(true, chainId)}
              >
                Trending
              </span>
              <span
                className={toggle ? '' : 'toggle-active'}
                onClick={() => handelAction(false, chainId)}
              >
                Top Collections
              </span>
            </div>
            <button
              className="sc-button style-4 text-dark ml-4 py-1 px-5 d-none d-md-block"
              onClick={() => history('/nft-collections')}
            >
              View All
            </button>
          </div>
        </div>
        <div>
          {toggle ? (
            <div className="row mt-md-4 mt-4 table-list">
              <div className="col-12">
                <div className="mb-4 d-flex justify-content-left  chain-list mt-0">
                  {Object.entries(chains).map(([k, v]) => (
                    <span
                      key={k}
                      className={`${chainId == k ? 'active' : ''} mr-2`}
                      onClick={() => {
                        setChainId(k);
                        handelAction(true, k);
                      }}
                    >
                      {v}
                    </span>
                  ))}
                </div>
              </div>
              <div className="col-md-6">
                <Table
                  columns={trendingColumns}
                  data={
                    Array.isArray(collections.data)
                      ? collections.data.slice(0, 6)
                      : []
                  }
                  isDataLoading={isDataLoading}
                  loadingMessage="Loading Collection..."
                />
              </div>
              <div className="col-md-6 d-none d-md-block">
                <Table
                  columns={trendingColumns}
                  data={
                    Array.isArray(collections.data)
                      ? collections.data.slice(6, 12)
                      : []
                  }
                  isDataLoading={isDataLoading}
                  loadingMessage="Loading Collection..."
                />
              </div>
            </div>
          ) : (
            <div className="row mt-md-5 mt-4 table-list">
              <div className="col-md-6">
                <Table
                  columns={mintingColumns}
                  data={Array.isArray(list) ? list.slice(0, 6) : []}
                  isDataLoading={isDataLoading}
                  loadingMessage="Loading Collection..."
                />
              </div>
              <div className="col-md-6 d-none d-md-block">
                <Table
                  columns={mintingColumns}
                  data={Array.isArray(list) ? list.slice(6, 12) : []}
                  isDataLoading={isDataLoading}
                  loadingMessage="Loading Collection..."
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
